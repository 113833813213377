import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../../shared/storage/local-storage.service';
import {AuthService} from '../../../shared/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-software-vendors',
  templateUrl: './software-vendors.component.html',
  styleUrls: ['./software-vendors.component.scss']
})
export class SoftwareVendorsComponent implements OnInit {


  page = 1;
  maxSize = 0;
  userData: any;
  platform: any;
  vendorsList = [];
  isLoading = false;
  submitted = false;
  isCreate = true;
  disbaleMe = false;
  checkboxes = {
    posIntegration: false, dispenseIntegration: false
  }
  selectedVendor = '';
  vendorForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/^\S.*$/)])
  })

  constructor(private storage: LocalStorageService, private modalService: NgbModal,
              private authService: AuthService, private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  async ngOnInit(): Promise<any> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    await this.fetchVendors(this.page);
  }

  loadingScreen() {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
  }

  openModal(content) {
    this.isCreate = true;
    this.selectedVendor = '';
    this.checkboxes = {
      posIntegration: false, dispenseIntegration: false
    }
    this.submitted = false;
    this.disbaleMe = false;
    this.vendorForm.reset();
    this.vendorForm.controls['name'].enable();
    this.modalService.open(content, {
      backdrop: 'static', size: 'md'
    });
  }


  async fetchVendors(page: number) {
    this.loadingScreen();
    this.isLoading = true;
    await this.authService.fetch_vendors(this.userData, this.platform._id, false, page, 10).then((response) => {
      if (response.status === 404) {
        this.resetArray();
        return;
      }
      if (response.status === 400) {
        this.resetArray();
        return;
      }
      if (response.status === 403) {
        this.resetArray();
        return;
      }
      if (response.status === 500) {
        this.resetArray();
        return;
      }
      this.vendorsList = response.results.map((item, index) => ({
        ...item, slNumber: ((page * 10) - 10) + (index + 1)
      }));
      this.maxSize = response.totalPages;
      this.spinner.hide();
      this.isLoading = false;
    });
  }

  async registerVendor(modal, posIntegration, dispenseIntegration) {
    this.submitted = true;
    if (this.vendorForm.invalid) {
      return;
    }

    if (!posIntegration.checked && !dispenseIntegration.checked) {
      return;
    }

    const body = {
      'name': this.vendorForm.value.name,
      'posIntegration': posIntegration.checked,
      'dispenseIntegration': dispenseIntegration.checked
    }


    this.disbaleMe = true;
    this.loadingScreen();
    await this.authService.register_update_Vendor(this.userData, body, this.platform._id, this.selectedVendor).then((response) => {
      if (response.status === 404) {
        this.disbaleMe = false;
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error(response.error.hasOwnProperty('error') ? response.error.error : response.error.message, 'Message', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 400) {
        this.disbaleMe = false;
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error(response.error.hasOwnProperty('error') ? response.error.error : response.error.message, 'Message', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.toastr.clear();
      this.toastr.success(this.selectedVendor === '' ? 'Software vendor created successfully' : 'Software vendor updated successfully', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.fetchVendors(this.page);
      this.disbaleMe = false;
      this.submitted = false;
      this.vendorForm.reset();
      modal.dismiss('Cross-Click');
    }).catch(err => this.disbaleMe = false);

  }


  openConfirmModal(content, vendorData) {
    this.isCreate = false;
    this.submitted = true;
    this.selectedVendor = vendorData._id;
    this.vendorForm.controls['name'].setValue(vendorData.name);
    this.vendorForm.controls['name'].disable();
    this.checkboxes.dispenseIntegration = vendorData.dispenseIntegration;
    this.checkboxes.posIntegration = vendorData.posIntegration;
    this.disbaleMe = false;
    this.modalService.open(content, {
      backdrop: 'static', size: 'md'
    });
  }

  async deleteVendor(modal) {
    this.disbaleMe = true;
    this.loadingScreen();
    await this.authService.delte_vendor(this.userData, this.selectedVendor).then((response: any) => {
      if (response.status === 404) {
        this.disbaleMe = false;
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error(response.error.hasOwnProperty('error') ? response.error.error : response.error.message, 'Message', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 400) {
        this.disbaleMe = false;
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error(response.error.hasOwnProperty('error') ? response.error.error : response.error.message, 'Message', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 500) {
        this.disbaleMe = false;
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error(response.error.hasOwnProperty('error') ? response.error.error : response.error.message, 'Message', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.toastr.clear();
      this.toastr.error('Vendor deleted successfully', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.disbaleMe = false;
      this.spinner.hide();
      this.fetchVendors(this.page);
      modal.dismiss('Cross-Click');
    }).catch(err => this.disbaleMe = false);
  }

  resetArray() {
    this.vendorsList = [];
    this.spinner.hide();
    this.disbaleMe = false;
    this.isLoading = false;
    this.maxSize = 0;
  }

  async pagination(page: number) {
    this.page = page;
    await this.fetchVendors(this.page);
  }
}
