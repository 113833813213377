<section id="shopping-cart">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title">Product List</h2>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12 col-12">
              <fieldset>
                <div class="input-group box-size">
                  <input type="text" #prdName class="form-control"
                         placeholder="Search Product" (keyup.enter)="filterUpdate(prdName.value)"
                         (keyup.backspace)="clearbox()">
                  <div class="input-group-append cursor-pointer" (click)="filterUpdate(prdName.value)">
                    <span class="btn btn-secondary"><i class="ft-search"></i></span>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-12">
              <button class="btn primary-btn mr-1 float-right" (click)="openImportModal(import)"><i
                class="ft-upload-cloud mr-1"></i>Import
              </button>
              <select (change)="OnChangeLimit($event)" class="form-control mr-1 float-right" style="width: 150px;">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="card-body">
            <h4 class="card-title text-center" *ngIf="isloading">Loading Products....</h4>
            <h4 class="card-title text-center" *ngIf="!isloading && productList.length === 0">Products not found</h4>
            <div class="table-responsive mt-1" *ngIf="productList.length > 0">
              <table class="table text-center m-0">
                <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Barcode</th>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Availability</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let product of productList;let i = index;">
                  <td>{{ product.slNumber }}</td>
                  <td>{{ product?.barCode }}</td>
                  <td>
                    <img src="{{(product.productImage.trim() === '' || !product.productImage)
                  ? 'assets/img/defaultProd.png'
                  :product.productImage}}" alt="" width="50" height="50">
                  </td>
                  <td>{{ product.productName }}</td>
                  <td>{{ product.category }}</td>
                  <td>${{ product.productCost }}</td>
                  <td>{{ product.stock }}</td>
                  <td>
                    <a href="javascript:;" class="danger" (click)="open(confirm,product)">
                      <i class="ft-trash font-medium-3"></i>
                    </a>
                    <a href="javascript:;" class="primary mx-2" (click)="openModal(content,product)">
                      <i class="ft-edit font-medium-3"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <ngb-pagination class="float-right" [collectionSize]="this.maxPageSize * 10"
                            (pageChange)="pagination($event)" [(page)]="pageTracker" [maxSize]="5" [rotate]="true"
                            [boundaryLinks]="true">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #confirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Product!</h4>
  </div>
  <div class="modal-body text-center">
    <p>Would you like to delete this product?</p>
  </div>
  <div class="modal-footer text-center justify-content-center">
    <button type="button" class="btn btn-danger" (click)="deleteProduct()">Yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Product Details</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row match-height">
      <!-- Basic Form starts -->
      <div class="col-12">
        <form [formGroup]="stockForm" (ngSubmit)="editProduct(toogle_Switch)">
          <div class="form-row">
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label for="basic-form-7">Barcode</label>
                <input type="text" id="basic-form-7" class="form-control" formControlName="productSKU">
                <p *ngIf="submitted && stk.productSKU.hasError('required')" class="text-danger">Barcode is
                  required</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label for="basic-form-1">Product Name</label>
                <input type="text" id="basic-form-1" class="form-control" formControlName="productName">
                <p *ngIf="submitted && stk.productName.hasError('required')" class="text-danger">Product name is
                  required</p>
              </div>
            </div>

          </div>
          <div class="form-row">
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label for="basic-form-3">Stock Availability</label>
                <input type="number" min="0" id="basic-form-3" class="form-control" formControlName="stock">
                <p *ngIf="submitted && stk.stock.hasError('required')" class="text-danger">Stock
                  Availability
                  is required</p>
                <p *ngIf="stockForm.controls['stock'].invalid" class="text-danger">Invalid stock</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label for="basic-form-6">Price</label>
                <input type="text" id="basic-form-6" class="form-control" formControlName="cost">
                <p *ngIf="submitted && stk.cost.hasError('required')" class="text-danger">Price is
                  required</p>
                <p *ngIf="stk.cost.hasError('pattern')" class="text-danger">Invalid Price</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label>Product Discontinuity</label><br>
              <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
                <input type="checkbox" [checked]="toogleSwitch" #toogle_Switch
                       (change)="toogleSwitch = toogle_Switch.checked" class="custom-control-input"
                       id="custom-switch-2">
                <label class="custom-control-label mr-1" for="custom-switch-2">
                  <span>{{ toogleSwitch ? 'Discontinued' : 'Continued' }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button type="submit" class="btn secondary-btn"><i class="ft-check-square mr-1"></i>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #create let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Add Stock</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="stockForm" (ngSubmit)="OnSubmit()">
          <div class="form-row">
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label>Product SKU</label>
                <input type="text" class="form-control" formControlName="productSKU">
                <p *ngIf="submitted && stk.productSKU.hasError('required')" class="text-danger">ProductSKU is
                  required</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label for="basic-form-1">Product Name</label>
                <input type="text" class="form-control" formControlName="productName">
                <p *ngIf="submitted && stk.productName.hasError('required')" class="text-danger">Product name is
                  required</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label>Category</label>
                <div class="form-group">
                  <ng-select formControlName="category" [items]="categoryList" bindLabel="categoryName"
                             bindValue="categoryName"
                             placeholder="Select Category" [(ngModel)]="selectedCategory">
                  </ng-select>
                  <!--                  <p *ngIf="submitted && stk.category.hasError('required')" class="text-danger">Category is-->
                  <!--                    required</p>-->
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label>Stock Availability</label>
                <input type="number" class="form-control" formControlName="stock">
                <p *ngIf="submitted && stk.stock.hasError('required')" class="text-danger">Product name
                  is required</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-12">
              <div class="form-group mb-2">
                <label>Price</label>
                <input type="text" class="form-control" formControlName="cost">
                <p *ngIf="submitted && stk.cost.hasError('required')" class="text-danger">Product name is
                  required</p>
                <p *ngIf="submitted && stk.cost.hasError('pattern')" class="text-danger">Invalid Price</p>
              </div>
            </div>

          </div>
          <button type="submit" class="btn primary-color"><i class="ft-check-square mr-1"></i>Save</button>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #import let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">INSTRUCTION</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul>
      <li>The below file contains entire list of global products.</li>
      <li>Will upload only those products whose row are completely filled.</li>
      <li>Please DO NOT edit anything in red highlighted row.</li>
      <li>For further instructions place your cursor on the red highlighted cell in the excel spreadsheet.</li>
      <li>While uploading the edited file convert the excel to CSV.</li>
    </ul>
    <button class="btn secondary-btn" (click)="download()"><i class="ft ft-download-cloud mr-1"></i>Sample File</button>
    <label for="productsUpload" class="btn primary-btn float-right cursor-pointer"><i
      class="ft ft-upload-cloud mr-1"></i>Upload</label>
    <input type="file" accept="text/csv" id="productsUpload" class="d-none" (change)="uploadCSv($event)">
  </div>
</ng-template>

