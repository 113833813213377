import {RouteInfo} from './vertical-menu.metadata';
import {upcomingOrders} from './vertical-menu-routes.config';

// Sidebar menu Routes and data
// ALWAYS FOLLOW THIS STRUCTURE PLATFORM -> PHARMACY -> CUSTOMER = PlatPharCust
export const PHARMACY_ROUTES: RouteInfo[] = [
  // {
  //   path: '/new-order',
  //   title: 'Create Order',
  //   icon: 'ft-clipboard',
  //   class: 'dropdown nav-item',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  //   category: 'Cust'
  // },
  {
    path: '/my-scripts',
    title: 'My Scripts',
    icon: 'ft-archive',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'Cust'
  },
  {
    path: '/cust-order-history',
    title: 'My Orders',
    icon: 'ft-clipboard',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'Cust'
  },

  {
    path: '/mails-from-pharmacy',
    title: 'Verifications',
    icon: 'ft-mail',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'Cust'
  },
  {
    path: '/orders',
    title: 'Orders',
    icon: 'ft-clipboard',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger mt-1 mr-2 no-right',
    isExternalLink: false,
    submenu: [],
    category: 'PlatPhar'
  }, {
    path: '/upcoming-orders',
    title: 'Scripts On File',
    icon: 'ft-package',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger mt-1 mr-2 no-right',
    isExternalLink: false,
    submenu: [],
    category: 'PlatPhar'
  },
  {
    path: '/platform',
    title: 'Platform',
    icon: 'ft-home',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'Mast'
  },
  {
    path: '/customer-leads',
    title: 'Leads',
    icon: 'ft-file-text',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'MastPlat'
  },
  {
    path: '/orders-history',
    title: 'Order History',
    icon: 'ft-book',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'PlatPhar'
  }, {
    path: '/customer',
    title: 'Customer',
    icon: 'ft-users',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'PlatPhar'
  }, {
    path: '/list',
    title: 'Products',
    icon: 'ft-archive',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'PlatPhar'
  },
  // {
  //   path: '/leads',
  //   title: 'Leads',
  //   icon: 'ft-archive',
  //   class: 'dropdown nav-item',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  //   category: 'PlatPhar'
  // },
  {
    path: '/pharmacy',
    title: 'Pharmacy',
    icon: 'ft-home',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'Plat'
  }, {
    path: '/staffs',
    title: 'Staff Management',
    icon: 'ft-user-plus',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'PlatPhar'
  },
  {
    path: '/audit-logs',
    title: 'Reports',
    icon: 'ft-file-text',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'MastPlat'
  }, {
    path: '/global-prod-list',
    title: 'Global Product List',
    icon: 'ft-menu',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'PlatPhar'
  },
  {
    path: '/categories',
    title: 'Product Categories',
    icon: 'fa fa-table',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'PlatPhar'
  },
  {
    path: '/pharmacy-info',
    title: 'Pharmacy Info',
    icon: 'ft-home',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'Phar'
  }, {
    path: '/software-vendors',
    title: 'Software Vendors',
    icon: 'ft-inbox',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: 'Plat'
  },
  {
    path: '',
    title: 'My Profile',
    icon: 'ft-settings',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/edit-profile',
        title: 'Edit Profile',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: 'PlatPharCust'
      }, {
        path: '/settings',
        title: 'Settings',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: 'PlatPharCust'
      }, {
        path: '/change-password',
        title: 'Change password',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: 'PlatPharCust'
      },
    ],
    category: 'PlatPharCust'
  }
];
