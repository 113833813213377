import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableComponent, ColumnMode} from '@swimlane/ngx-datatable';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {WizardComponent, ArchwizardModule} from 'angular-archwizard';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

const moment = require('moment')
let unMatchedProd = [];

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit {

  pharmacyList = [];
  isLoading = true;
  pharmacySelected = null;
  selectedPharmacyState: any;
  lockButton = false;
  choiceMade = '';
  post_code_pharmacyList = [];
  showPostcode = false;
  closeResult = '';
  showMedicines = false;
  count = 1;
  trackFileUpload = false;
  show = '';
  rows: any[] = [];
  MedicineBodyTable = [];
  userData: any;
  submitted = false;
  selectedOrder: any;
  ItemsTotal: number;
  showUpload = false;
  userStatus = '';
  showDoc = false;
  selectedPharmacy = null;
  pickup = true;
  platform: any;
  validationObject = {
    required: false,
    invalid: false,
  }
  frontFile: any;
  customerNote = null;
  backFile: any;
  pharmacyUser: any;
  viewScript = '';
  myorderObj: any = {};
  selected_Product = null;
  tempStorage = [];
  orderedItems = [];
  trackingItems = [];
  disableBTn = false;
  collapse = true;
  collapse1 = true;
  collapse2 = true;
  deliverTypeSelected = false;
  deliverModal = {
    delivery: false,
    pickup: false
  }
  states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];
  showUploadMessage = '';
  productList: any[] = [];
  scriptImage = '';
  Medicinerows = [];
  ScriptRows = [];
  unMatchedScripts = [];
  removableProd = {
    productName: '', type: '', token: ''
  }
  selectedProduct: any;
  selected_index = -1;

  public ColumnMode = ColumnMode;
  scriptForm = new FormGroup({
    token: new FormControl('', [
      Validators.pattern(/^(1|2)[A-Za-z0-9]{17,20}$|^3[A-Za-z0-9]{14,17}-(?:[1-9]|[1-4][0-9]|50)$/)
      , Validators.required])
  });
  disableBtn = false;
  index: number;
  showMessageBlock = true;
  isCollapsed = true;
  check = false;
  frontLicense = '';
  backLicense = '';
  medicareCard = '';
  pensionCard = '';
  fileCount = 0;
  selectedAddress: any;
  drivingLicendseType = '';
  totalCost = 0;
  GST_amount = 0;
  GST_percentage = 0;
  totalAmount_to_paid = 0;

  addressForm = new FormGroup({
    addressType: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  })
  userAddress = [];
  selectedUserAddress = null;
  defaultPharmacy: any;
  delivery_Instruction = '';
  selectedScript = null;
  selectedMedicince: any;
  selectedIndex: any;
  selectedCustomer: any;
  orderBy: any;
  type = null;
  uploadVia = '';
  file = null;
  orderedItemsModal = {
    'token': '',
    'product_brand_name': '',
    'product_name': '',
    'medication_strength': '',
    'quantity': '',
    'price': 0,
    'repeats': 0,
    'instruction': '',
    'note': '',
    'globalProductId': '',
  }
  showAuthTPAForm = false;
  validation = false;
  uploadType = '';
  selectedState = null;
  searchByPharmacty = true;
  showUrl = false;
  scriptImage_for = 'for_complete_order';
  selectedMedicine_Index: number;
  selectedStep = '';
  tokenData = null;
  uploadedToken = '';
  myscripts = [];
  fetchedPharmacies = [];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  @ViewChild('tableResponsive') tableResponsive: any;
  @ViewChild(WizardComponent) wizard: any;

  protected readonly Number = Number;

  constructor(private authService: AuthService, private toastr: ToastrService,
              private downloadFile: DownloadFileService, private storage: LocalStorageService,
              private router: Router, private activatedRoute: ActivatedRoute,
              private modalService: NgbModal, private spinner: NgxSpinnerService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.activatedRoute.queryParams.subscribe((response: { data: string }) => {
      this.myscripts = JSON.parse(response.data);
      this.authService.fetch_pharmacies_product_match(this.userData, this.userData.auth.data._id, 'true',
        {'scriptIds': this.myscripts}).then((pharmacies: any) => {
        if (pharmacies.status === 400) {
          this.isLoading = false;
          this.pharmacyList = [];
          return;
        }
        if (pharmacies.status === 404) {
          this.isLoading = false;
          this.pharmacyList = [];
          return;
        }
        if (pharmacies.status === 500) {
          this.isLoading = false;
          this.pharmacyList = [];
          return;
        }
        this.pharmacyList = pharmacies.data.map(ele => ({
          ...ele, collapse: true
        }));
        this.isLoading = false;
      })

    });
    if (this.selectedCustomer === undefined) {
      await this.authService.fetchUser(this.userData).then(async (customer: any) => {
        this.selectedCustomer = customer;
      })
    }
    if (this.orderBy === undefined) {
      this.orderBy = 'Customer'
    }

    if (this.userAddress.length === 0) {
      this.loadUserAddress();
    }
  }

  loadUserAddress() {
    this.authService.fetch_user_address(this.userData).then((resp) => {
      this.userAddress = resp;
      this.userAddress.map((address) => {
        address.completeAddress = address.address + ' ' + address.suburb + ' ' + address.state + ' ' + address.postcode;
        address.icon = 'ft-x-circle text-danger';
      });
    })
  }

  async open(content, size, step = 'step-2') {
    this.selectedStep = '';
    this.selectedStep = step;
    this.scriptImage_for = 'for_complete_order';
    this.uploadType = ''
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  AddScriptImage(modal, size, index) {
    this.uploadType = ''
    this.scriptImage_for = 'for_each_medicine';
    this.selectedMedicine_Index = index;
    this.modalService.open(modal, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openAddressModal(content, size) {
    this.addressForm.reset();
    this.selectedState = null;
    this.submitted = false;
    if (this.userAddress.length === 3) {
      this.toastr.clear();
      this.toastr.info('Maximum User address limit exceeded', 'Message', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  async confirmation(content, size,) {
    this.lockButton = false;
    this.submitted = true;
    if (this.orderedItems.length === 0) {
      this.toastr.clear();
      this.toastr.warning('Kindly choose the medication', 'Warning!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (!this.deliverTypeSelected) {
      this.toastr.info('Please Select the Delivery Type to place the Order', 'Info!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    await this.open(content, 'sm');
  }


  openDeliveryModal(content, size, deliveryType) {
    this.choiceMade = '';
    this.showAuthTPAForm = false;
    this.deliverModal.delivery = false;
    this.deliverModal.pickup = false;
    if (this.selectedAddress === undefined) {
      this.toastr.clear();
      this.toastr.error('Please select your address', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.deliverModal.delivery = false;
      this.deliverModal.pickup = false;
      return;
    }
    if (deliveryType) {
      this.deliverModal.pickup = true;
      this.deliverModal.delivery = false;
    } else {
      this.deliverModal.delivery = true;
      this.deliverModal.pickup = false;
    }

    this.deliverTypeSelected = true;
    this.pickup = deliveryType;
    this.myorderObj = {
      userId: this.selectedCustomer._id,
      pharmacyId: this.selectedPharmacy.pharmacyId,
      pickup: this.pickup,
      address: this.selectedAddress.address,
      suburb: this.selectedAddress.suburb,
      state: this.selectedAddress.state,
      postcode: this.selectedAddress.postcode,
      usageId: '65168634b795d0617ab4d84e',
      uploadedScript: this.scriptImage === '' ? '' : this.scriptImage,
    }
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModal(content, type: string) {
    this.show = type;
    this.modalService.open(content, {size: 'md', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // This function is used in open


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  rowDetailsToggleExpand(row) {
    this.tableRowDetails.rowDetail.toggleExpandRow(row);
  }

  updateQuantity(operation: string, item, type = 'match') {
    if (operation === 'increase') {
      this.trackingItems.map((data) => {
        if (data.product_name === item.product_name) {
          data.quantity = data.quantity + 1;
        }
        return data;
      });
      if (type === 'unmatch') {
        this.unMatchedScripts.map((data) => {
          if (data.erxNames === item.erxNames) {
            data.quantity = data.quantity + 1;
          }
          return data;
        });
        this.orderedItems.map((data) => {
          if (data.product_name === item.product_name) {
            data.quantity = data.quantity + 1;
          }
          return data;
        });
      } else {
        this.orderedItems.map((data) => {
          if (data.product_name === item.product_name) {
            data.quantity = data.quantity + 1;
          }
          return data;
        });
      }
    }
    if (operation === 'decrease') {
      this.trackingItems.map((data) => {
        if (data.product_name === item.product_name) {
          const temp = data.quantity - 1;
          data.quantity = temp <= 0 ? 1 : temp;
        }
        return data;
      });
      if (type === 'unmatch') {
        this.unMatchedScripts.map((data) => {
          if (data.erxNames === item.erxNames) {
            const temp = data.quantity - 1;
            data.quantity = temp <= 0 ? 1 : temp;
          }
          return data;
        });

        this.orderedItems.map((data) => {
          if (data.product_name === item.product_name) {
            const temp = data.quantity - 1;
            data.quantity = temp <= 0 ? 1 : temp;
          }
          return data;
        });
      } else {
        this.orderedItems.map((data) => {
          if (data.product_name === item.product_name) {
            const temp = data.quantity - 1;
            data.quantity = temp <= 0 ? 1 : temp;
          }
          return data;
        });
      }
    }
    if (this.orderedItems.length) {
      if (this.pickup) {
        this.forPickUpCalculation();
      } else {
        this.amountForDelivery();
      }
    }
  }

  placeOrder(content) {
    const note = {
      customerNote: true, addedBy_userId: this.userData.auth.data._id,
      note_addedBy_userName: this.userData.auth.data.firstName, fallowUp_timeStamp: moment().format(),
      fallowUp_note: this.customerNote
    }

    this.myorderObj = {
      ...this.myorderObj, ['deliveryInstruction']: this.delivery_Instruction,
      ['scriptId']: !this.tokenData ? '' : this.tokenData._id,
      'orderedItem': this.orderedItems,
      'notes': this.customerNote ? [note] : []
    };
    this.lockButton = true;
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
    this.authService.createOrder(this.userData, this.myorderObj, 'false').then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        this.lockButton = false;
        return;
      } else if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        this.lockButton = false;
        return;
      } else if (resp.status === 404) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        this.lockButton = false;
        return;
      } else {
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.success('Order placed', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.modalService.dismissAll(content);
        if (this.orderBy === 'Customer') {
          this.router.navigate(['/cust-order-history']);
        } else {
          this.router.navigate(['/orders']);
        }
      }
    }).catch(err => {
      if (err.status === 404) {
        this.toastr.clear();
        this.toastr.error(err.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        this.lockButton = false;
        return;
      }
      if (err.status === 400) {
        this.toastr.clear();
        this.toastr.error(err.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        this.lockButton = false;
        return;
      }
      if (err.status === 500) {
        this.toastr.clear();
        this.toastr.error(err.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        this.lockButton = false;
        return;
      }
      this.spinner.hide();
      this.modalService.dismissAll(content);
    })
  }

  add(smallModal) {
    this.submitted = true;
    if (this.scriptForm.invalid) {
      return;
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const medicineObj = {
      token: this.scriptForm.value.token,
      data: [],
    }
    const medicineData = [];
    this.uploadedToken = this.scriptForm.value.token
    this.authService.getMedication_token_scan(this.userData, this.scriptForm.value.token.trim(), this.selectedCustomer, this.selectedPharmacy.pharmacyId, 'customer').then((resp: any) => {
      if (resp.status === 400) {
        this.spinner.hide();
        this.toastr.error(Object.keys(resp.error).includes('message') ? resp.error.message : resp.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (resp.status === 500) {
        this.spinner.hide();
        this.toastr.error('Oops! Looks like something went wrong. Please try again later!', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.spinner.hide();
      this.ScriptRows = [...this.ScriptRows, ...resp];
      this.ScriptRows.map((data) => {
        data.from = 'erx';
        return data;
      });
      this.modalService.open(smallModal, {size: 'md', backdrop: 'static'});
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      this.toastr.error(err.error.error, 'Error', {
        positionClass: 'toast-top-center'
      });
    })
    this.submitted = false;
    this.scriptForm.reset();
    this.modalService.dismissAll();
  }

  getPharmacy(event: any) {
    this.searchByPharmacty = true;
    unMatchedProd = [];
    this.index = -1;
    this.disableBTn = false;
    this.customerNote = null;
    this.submitted = false;
    this.selectedState = null;
    this.orderedItems = [];
    this.trackingItems = [];
    this.selectedPharmacy = null;
    this.fetchedPharmacies = [];
    this.fetchedPharmacies.push(event);
    this.fetchedPharmacies.map(pharma => {
      pharma.collapse = true;
      return pharma;
    });
    this.fetchedPharmacies.map((pharma) => {
      if (pharma.medicationAvailable.length > 0) {
        pharma.medicationAvailable.map((medi) => {
          medi.matchedScript = {...medi.matchedScript, showRow: true};
          return medi;
        });
      }
      if (pharma.medicationNotAvailable.length > 0) {
        pharma.medicationNotAvailable.map((unmatch) => {
          unmatch.showRow = true;
          return unmatch;
        })
      }
      return pharma;
    });
  }


  extactMatchFound(pharmacy) {
    this.orderedItems = [];
    this.trackingItems = [];
    this.ScriptRows = [];
    this.unMatchedScripts = [];
    this.fetchedPharmacies.map((pharma: any) => {
      if (pharma.pharmacyId === pharmacy.pharmacyId) {
        pharma.medicationAvailable.map(medi => {
          //check if the script's medication display name matches exactly one of the eRxProductNames, if so it's a match.
          if (medi.matchingResponseOfMedicationFound.length === 1 &&
            medi.matchingResponseOfMedicationFound[0].erxProductName.includes(medi.matchedScript.medication_display)) {
            medi.matchedScript.showdropdown = true;
            const orderItemObject = {
              'token': medi.matchedScript.token,
              'product_brand_name': medi.matchedScript.product_brand_name,
              'scriptName': medi.matchedScript.medication_display,
              'product_name': medi.matchingResponseOfMedicationFound[0].pharmacyProductName,
              'medication_strength': medi.matchedScript.medication_strength,
              'quantity': 1,
              'price': medi.matchingResponseOfMedicationFound[0].productCost,
              'stockStatus': medi?.matchingResponseOfMedicationFound[0].availableStock > 0 && !pharmacy.pharmacyDetails.acceptBackOrders ? 'In-stock' :
                medi?.matchingResponseOfMedicationFound[0].availableStock <= 0 && pharmacy.pharmacyDetails.acceptBackOrders ? 'Back order' :
                  medi?.matchingResponseOfMedicationFound[0].availableStock > 0 && pharmacy.pharmacyDetails.acceptBackOrders ? 'In-stock' : 'Out of stock',
              'repeats': medi.matchedScript.repeats,
              'instruction': medi.matchedScript.instruction,
              'note': medi.matchedScript.note,
              'duration': '',
              'globalProductId': medi.matchingResponseOfMedicationFound[0].GlobalProductId,
              'GlobalProductOriginalName': medi.matchingResponseOfMedicationFound[0].GlobalProductOriginalName,
              'matchStatus': 'Match',
              'scriptData': {
                'uploadedScript': '',
                'type': ''
              }
            }
            if (medi.matchingResponseOfMedicationFound[0].availableStock <= 0 && !pharmacy.pharmacyDetails.acceptBackOrders) {
              this.disableBTn = false;
              this.toastr.clear();
              this.toastr.info(medi.matchingResponseOfMedicationFound[0].pharmacyProductName + '  is currently out of stock, and ' + pharmacy.pharmacy_name + ' is not accepting backorders at the moment.', 'Message', {
                positionClass: 'toast-top-center'
              });
              return;
            } else {
              this.disableBTn = false;
            }


            medi.matchedScript = {
              ...medi.matchedScript, 'script_status': 'Match',
              'itemsOrdered': [orderItemObject],
              'erxNames': medi.matchedScript.product_name === '' ? medi.matchedScript.product_brand_name :
                medi.matchedScript.product_name.includes(medi.matchedScript.medication_strength) ? medi.matchedScript.product_name : medi.matchedScript.product_name + ' ' + medi.matchedScript.medication_strength,
              'trackingItems': [{...orderItemObject, 'scriptData': medi.matchedScript}]
            };
            this.ScriptRows = [...this.ScriptRows, medi.matchedScript];
          } else if (medi.matchingResponseOfMedicationFound.length === 1) {
            const matchedScriptbrandName = medi.matchedScript.product_brand_name;
            const medicationbrandName = medi.matchingResponseOfMedicationFound[0].brandName;
            const matchedScriptProdName = medi.matchedScript.product_name;
            const medicationProdName = medi.matchingResponseOfMedicationFound[0].originalName.split('')[0];
            const medicationStrength = String(medi.matchingResponseOfMedicationFound[0].size_amount) + medi.matchingResponseOfMedicationFound[0].size_measure;

            if (medi.matchedScript.medication_strength.toLowerCase().includes(medicationStrength.toLowerCase()) &&
              medi.matchedScript.productType.toLowerCase() === medi.matchingResponseOfMedicationFound[0].productType.toLowerCase() &&
              matchedScriptbrandName.toLowerCase() === medicationbrandName.toLowerCase() && matchedScriptProdName.toLowerCase() && medicationProdName.toLowerCase()
            ) {
              medi.matchedScript.showdropdown = true;
              const orderItemObject = {
                'token': medi.matchedScript.token,
                'product_brand_name': medi.matchedScript.product_brand_name,
                'scriptName': medi.matchedScript.medication_display,
                'product_name': medi.matchingResponseOfMedicationFound[0].pharmacyProductName,
                'medication_strength': medi.matchedScript.medication_strength,
                'quantity': 1,
                'price': medi.matchingResponseOfMedicationFound[0].productCost,
                'stockStatus': medi?.matchingResponseOfMedicationFound[0].availableStock > 0 && !pharmacy.pharmacyDetails.acceptBackOrders ? 'In-stock' :
                  medi?.matchingResponseOfMedicationFound[0].availableStock <= 0 && pharmacy.pharmacyDetails.acceptBackOrders ? 'Back order' :
                    medi?.matchingResponseOfMedicationFound[0].availableStock > 0 && pharmacy.pharmacyDetails.acceptBackOrders ? 'In-stock' : 'Out of stock',
                'repeats': medi.matchedScript.repeats,
                'instruction': medi.matchedScript.instruction,
                'note': medi.matchedScript.note,
                'duration': '',
                'globalProductId': medi.matchingResponseOfMedicationFound[0].GlobalProductId,
                'GlobalProductOriginalName': medi.matchingResponseOfMedicationFound[0].GlobalProductOriginalName,
                'matchStatus': 'Match',
                'scriptData': {
                  'uploadedScript': '',
                  'type': ''
                }
              }
              medi.matchedScript = {
                ...medi.matchedScript, 'script_status': 'Match',
                'itemsOrdered': [orderItemObject],
                'erxNames': medi.matchedScript.product_name === '' ? medi.matchedScript.product_brand_name :
                  medi.matchedScript.product_name.includes(medi.matchedScript.medication_strength) ? medi.matchedScript.product_name : medi.matchedScript.product_name + ' ' + medi.matchedScript.medication_strength,
                'trackingItems': [{...orderItemObject, 'scriptData': medi.matchedScript}]
              };
              this.ScriptRows = [...this.ScriptRows, medi.matchedScript];
            } else {
              medi.matchedScript.showdropdown = false;
            }
          } else {
            medi.matchedScript.showdropdown = false;
          }
          medi.matchedScript = {
            ...medi.matchedScript,
            'ProdStock_status': '',
            'prodPrice': '',
            'resetDropdown': null,
            'resetDropdownId': null
          };
          return medi;
        });

        this.ScriptRows.forEach(data => {
          this.trackingItems = [...this.trackingItems, ...data.trackingItems];
        });

        if (pharma.medicationNotAvailable.length > 0) {
          pharma.medicationNotAvailable = pharma.medicationNotAvailable.map(item => ({
            ...item,
            'script_status': 'Unmatched',
            'itemsOrdered': [],
            'trackingItems': [],
            'erxNames': item.product_name === '' ? item.product_brand_name :
              item.product_name.includes(item.medication_strength) ? item.product_name : item.product_name + ' ' + item.medication_strength
          }));

          pharma.medicationNotAvailable.forEach(unmatch => this.unMatchedScripts.push(unmatch));
          this.unMatchedScripts.map(data => {
            data.quantity = 1;
            return data;
          });
          pharma.medicationNotAvailable.map(medi => {
            const check = this.ScriptRows.some((scr) => medi.erxNames.toLowerCase() === scr.erxNames.toLowerCase());
            if (!check) {
              this.ScriptRows = [...this.ScriptRows, medi];
            }
          })
        } else {
          this.unMatchedScripts = [];
        }
      }
      return pharma;
    });

    this.ScriptRows.forEach(data => {
      this.orderedItems = [...this.orderedItems, ...data.itemsOrdered];
    });
  }


  upload(file: any, modal) {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
    this.authService.uploadScript_image(this.userData, file.target.files[0]).then((resp: any) => {
      this.toastr.success('Script Uploaded Successfully', 'Success!', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
      if (this.scriptImage_for === 'for_each_medicine') {
        const script = {
          'uploadedScript': resp.location,
          'type': this.uploadType
        }
        this.orderedItems[this.selectedMedicine_Index].scriptData = script;
      } else {
        this.scriptImage = resp.location;
      }
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(this.scriptImage) ? true : false;
      modal.dismiss('Cross-Click');
    });
  }

  searh_phar_post(postcode: any) {
    this.selectedState = null;
    this.selectedPharmacyState = null;
    this.states = [];
    this.states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];
    if (postcode.value === '') {
      this.validationObject.required = true;
      this.validationObject.invalid = false;
      return;
    }
    this.validationObject.required = false;
    this.validationObject.invalid = false;
    const postCode = Number(postcode.value);
    this.authService.fetch_pharmacy_postcode(this.userData, this.platform, 'postcode', postCode).then((resp: any) => {
      if (resp.status === 404) {
        this.toastr.info('No Pharmacy Found', 'Info!', {
          positionClass: 'toast-top-center'
        });
        this.post_code_pharmacyList = [];
      } else {
        this.post_code_pharmacyList = resp.map(data => {
          data.collapse = true;
          return data;
        });
      }
    }).catch(err => {
    });
  }

  validatepostcode(postcode) {
    const regex = /[0-9]{4}/;
    if (!regex.test(postcode.value)) {
      this.validationObject.invalid = true;
      this.validationObject.required = false;
      this.post_code_pharmacyList = [];
    } else {
      this.post_code_pharmacyList = [];
      this.validationObject.invalid = false;
      this.validationObject.required = false;
      this.searh_phar_post(postcode);
    }
  }


  onChangeProduct(event: any, index: number) {
    if (this.selectedStep === 'step-3') {
      this.selectedProduct = event;
      if (this.selectedPharmacy.acceptBackOrders) {
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          return;
        }
        if (this.selectedProduct.stock <= 0) {
          this.toastr.info('This product is currently on back order - there may be additional delays while we order stock in for you', 'Info', {
            positionClass: 'toast-top-center',
          });
        }
      } else {
        if (this.selectedProduct.stock <= 0) {
          this.toastr.clear();
          this.toastr.error('The selected product is out of stock.', 'Error', {
            positionClass: 'toast-top-center',
          });
          return;
        }
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          return;
        }
      }
      if (this.orderedItems.length > 0) {
        const check = this.orderedItems.some((d: any) => {
          return d.product_name === this.selectedProduct.productName;
        });

        if (check) {
          this.toastr.info('Medicine is already in the list', 'Info', {
            positionClass: 'toast-top-center'
          });
          return;
        }
        this.ScriptRows.map((data, rindex) => {
          if (rindex === index) {
            data.from = 'disableMe'
          }
          return data;
        })
        this.ScriptRows.push(
          {
            'token': this.uploadedToken,
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': Number(this.selectedProduct.productCost),
            'repeats': 'NA',
            'instruction': '',
            'note': '',
            'eRx': true,
            'enable': true,
            'from': 'not-erx', 'dropdownIndex': index
          });


        this.Medicinerows.push({
          ...this.selectedProduct,
          ['token']: this.uploadedToken,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': this.uploadedToken,
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': true,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );
      } else {
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['token']: this.uploadedToken,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': this.uploadedToken,
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': true,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );
      }
    } else {
      this.selectedProduct = event;
      if (this.selectedPharmacy.acceptBackOrders) {
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          return;
        }
        if (this.selectedProduct.stock <= 0) {
          this.toastr.info('This product is currently on back order - there may be additional delays while we order stock in for you', 'Info', {
            positionClass: 'toast-top-center',
          });
        }
      } else {
        if (this.selectedProduct.stock <= 0) {
          this.toastr.clear();
          this.toastr.error('The selected product is out of stock.', 'Error', {
            positionClass: 'toast-top-center',
          });
          return;
        }
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          return;
        }
      }

      this.ScriptRows.map((data, rindex) => {
        if (rindex === index) {
          data.from = 'disableMe'
        }
        return data;
      })
      this.ScriptRows.push(
        {
          'token': this.uploadedToken,
          'product_brand_name': this.selectedProduct.brandName,
          'product_name': this.selectedProduct.productName,
          'medication_strength': '',
          'quantity': 1,
          'price': this.selectedProduct.productCost,
          'repeats': 'NA',
          'instruction': '',
          'note': '',
          'eRx': true, 'enable': true, 'from': 'not-erx', 'dropdownIndex': index
        });


      this.orderedItems.push(
        {
          'token': this.uploadedToken,
          'product_brand_name': this.selectedProduct.brandName,
          'product_name': this.selectedProduct.productName,
          'medication_strength': '',
          'quantity': 1,
          'price': this.selectedProduct.productCost,
          'repeats': 0,
          'instruction': '',
          'note': '',
          'globalProductId': this.selectedProduct.globalProductId,
          'eRx': false,
          'scriptData': {
            'uploadedScript': '',
            'type': ''
          },
          'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
        }
      );


      if (this.Medicinerows.length > 0) {
        const check = this.Medicinerows.some((d: any) => {
          return d._id === this.selectedProduct._id;
        });

        const duplicates = this.orderedItems.some((d: any) => {
          return d.product_name === this.selectedProduct.productName;
        });

        if (check && duplicates) {
          this.toastr.info('Medicine is already in the list', 'Info', {
            positionClass: 'toast-top-center'
          });
          return;
        }
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['token']: this.uploadedToken,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });


      } else {
        this.Medicinerows.push({
          ...this.selectedProduct, ['token']: this.uploadedToken,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
      }
    }

  }

  increase(Item, step = 'step-2') {
    if (step === 'step-2') {
      this.Medicinerows.map((item) => {
        if (Item._id === item._id) {
          item.quantity = item.quantity + 1;
          if (item.quantity > Item.stock) {
            this.toastr.clear();
            this.toastr.info(`Please select a quantity within the available stock range, as the requested quantity exceeds the available stock of ${Item.stock}`,
              'Info!', {
                positionClass: 'toast-top-center'
              });
            item.quantity = 1;
            return;
          }
        }
      });

      if (this.ScriptRows.length > 0) {
        this.ScriptRows.map((item) => {
          if (Item.product_name === item.product_name) {
            item.quantity = item.quantity + 1;
            if (item.quantity > Item.quantity) {
              item.quantity = 1;
              return;
            }
          }
        })
      }
      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.product_name === item.product_name) {
            item.quantity = item.quantity + 1;
            if (item.quantity > Item.quantity) {
              item.quantity = 1;
              return;
            }
          }
        })
      }
    } else {
      this.Medicinerows.map((item) => {
        if (Item.product_name === item.product_name) {
          item.quantity = item.quantity + 1;
          if (item.quantity > Item.stock) {
            this.toastr.clear();
            this.toastr.info(`Please select a quantity within the available stock range, as the requested quantity exceeds the available stock of ${Item.stock}`,
              'Info!', {
                positionClass: 'toast-top-center'
              });
            item.quantity = 1;
            return;
          }
        }
      });
      if (this.ScriptRows.length > 0) {
        this.ScriptRows.map((item) => {
          if (Item.product_name === item.product_name) {
            item.quantity = item.quantity + 1;
            if (item.quantity > Item.quantity) {
              item.quantity = 1;
              return;
            }
          }
        })
      }
      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.product_name === item.product_name) {
            item.quantity = item.quantity + 1;
            if (item.quantity > Item.quantity) {
              item.quantity = 1;
              return;
            }
          }
        })
      }
    }
  }

  openConfimationModal(confirm, item) {
    // , medicine, index, script
    // this.selectedMedicince = medicine;
    // this.selectedIndex = index;
    // this.selectedScript = script;
    this.selectedMedicince = item;
    this.modalService.open(confirm, {size: 'md', backdrop: 'static'});
  }


  decrease(Item, step = 'step-2') {
    if (step === 'step-2') {
      // this.Medicinerows.map((item) => {
      //   if (Item._id === item._id) {
      //     if (item.quantity > 1) {
      //       item.quantity = item.quantity - 1;
      //     }
      //
      //   }
      // });
      if (this.ScriptRows.length > 0) {
        this.ScriptRows.map((item) => {
          if (Item.product_name === item.product_name) {
            if (item.quantity > 1) {
              item.quantity = item.quantity - 1;
            }
          }
        });
        if (this.orderedItems.length > 0) {
          this.orderedItems.map((item) => {
            if (Item.product_name === item.product_name) {
              if (item.quantity > 1) {
                item.quantity = item.quantity - 1;
              }
            }
          })
        }
      }
    } else {
      this.Medicinerows.map((item) => {
        if (Item.product_name === item.productName) {
          if (item.quantity > 1) {
            item.quantity = item.quantity - 1;
          }
        }
      });

      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.product_name === item.product_name) {
            if (item.quantity > 1) {
              item.quantity = item.quantity - 1;
            }
          }
        })
      }
      if (this.ScriptRows.length > 0) {
        this.ScriptRows.map((item) => {
          if (Item.product_name === item.product_name) {
            if (item.quantity > 1) {
              item.quantity = item.quantity - 1;
            }
          }
        })
      }
    }
  }


  openConfimation(confirm, item, step = 'step-2') {
    if (step === 'step-2') {
      this.selectedMedicince = item;
    } else {
      this.selectedMedicince = {...this.selectedMedicince, ['productName']: item.product_name};
    }
    this.modalService.open(confirm, {size: 'md', backdrop: 'static'});
  }


  uploadLicence(file: any, type: string, uploadVia = 'fileExplore') {
    this.uploadVia = uploadVia;
    if (this.type === 'DriversLicense') {
      if (type === 'licenseFront') {
        this.frontFile = file;
      } else {
        this.backFile = file;
      }
    } else {
      this.file = file;
    }
  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }


  async SelectedPharmacy(pharmacy, index) {
    this.showMessageBlock = true;
    unMatchedProd = [];
    this.collapse = true;
    this.collapse1 = true;
    this.type = null;
    this.selectedPharmacy = pharmacy;

    this.selectedScript = null;
    let pharmcyAddress = '';
    for (const key in pharmacy.pharmacyAddress) {
      pharmcyAddress += pharmacy.pharmacyAddress[key] + ' ';
    }
    this.selectedPharmacy = {...this.selectedPharmacy, 'address': pharmcyAddress};
    this.fetchedPharmacies.map(ele => {
      if (pharmacy.pharmacyId === ele.pharmacyId) {
        ele.collapse = !ele.collapse;
      } else {
        ele.collapse = true;
      }
      return ele;
    });
    this.fetchedPharmacies.map((pharma: any) => {
      if (pharmacy.pharmacyId === pharma.pharmacyId) {
        if (pharma.medicationAvailable.length > 0) {
          pharma.medicationAvailable.map(medi => {
            medi.matchedScript = {
              ...medi.matchedScript,
              collapseText: true,
              partialMatchStatus: false,
              showRow: pharmacy.pharmacyId === pharma.pharmacyId,
              'ProdStock_status': '',
              'prodPrice': '',
              'resetDropdown': null,
              'resetDropdownId': null
            }
          });
        }
        if (pharma.medicationNotAvailable.length > 0) {
          pharma.medicationNotAvailable.map((script) => {
            script.showRow = pharmacy.pharmacyId === pharma.pharmacyId;
            script.collapseText = true;
            return script;
          })
        }
      }
      return pharma;
    });
    this.extactMatchFound(pharmacy);
    this.showUpload = false;
    this.userAddress.filter((address) => {
      if (address.verifiedPharmacies.length > 0) {
        address.icon = address.verifiedPharmacies.includes(this.selectedPharmacy.pharmacyId) ? 'ft-check text-success' : 'ft-x-circle text-danger';
      } else {
        address.icon = 'ft-x-circle text-danger'
      }
    });
    this.index = index;
  }


  OnPartialMatchUpdate(event, pharmacy, script) {

    this.fetchedPharmacies.map((pharma: any) => {
      if (pharmacy.pharmacyId === pharma.pharmacyId) {
        pharma.medicationAvailable.map((medication) => {
          if (medication.matchedScript.medication_display === script.medication_display) {
            medication.matchedScript.partialMatchStatus = event.target.checked;
            medication.matchedScript.resetDropdown = event.target.checked ? null : medication.matchedScript.resetDropdown;
            medication.matchedScript.prodPrice = event.target.checked ? '' : medication.matchedScript.prodPrice;
            medication.matchedScript.ProdStock_status = event.target.checked ? '' : medication.matchedScript.ProdStock_status;
            medication.matchedScript.resetDropdownId = event.target.checked ? null : medication.matchedScript.resetDropdownId;
          }
          return medication;
        });
      }
      return pharma;
    });


    const filtered = this.ScriptRows.filter((scr) => {
      return scr.medication_display !== script.medication_display;
    });
    this.ScriptRows = filtered;

    if (event.target.checked) {
      if (this.ScriptRows.length > 0 && !this.ScriptRows.find(scr => scr.medication_display === script.medication_display)) {
        this.ScriptRows.push(script);
      }
      if (this.ScriptRows.length === 0) {
        this.ScriptRows.push(script);
      }
    }
    this.ScriptRows.map((scr) => {
      if (scr.medication_display === script.medication_display) {
        scr.script_status = event.target.checked ? 'Unmatched' : scr.script_status;
      }
      return scr;
    });

    if (this.orderedItems.length > 0) {
      const tempProd = this.orderedItems.filter((prod) => {
        return prod.scriptName !== script.medication_display;
      });
      this.orderedItems = tempProd;
    }


    const unMatchScriptObj = {
      'token': script.token,
      'product_brand_name': script.product_brand_name,
      'scriptName': script.medication_display,
      'product_name': script.product_name,
      'medication_strength': script.medication_strength,
      'quantity': 1,
      'price': 0,
      'repeats': script.repeats,
      'instruction': script.instruction,
      'note': script.note,
      'duration': '',
      'globalProductId': '',
      'GlobalProductOriginalName': '',
      'matchStatus': 'Unmatched',
      'scriptData': {
        'uploadedScript': '',
        'type': ''
      }
    }
    this.orderedItems.push(unMatchScriptObj);
    if (this.trackingItems.length > 0) {
      const removeProd1 = this.trackingItems.filter((prod) => {
        return prod.scriptName !== script.medication_display;
      });
      this.trackingItems = removeProd1;
    }
    if (event.target.checked) {
      this.unMatchedScripts.push(this.ScriptRows.find(scr => scr.medication_display === script.medication_display));
    } else {
      const filtered = this.unMatchedScripts.filter(scr => scr.medication_display !== script.medication_display);
      this.unMatchedScripts = filtered;
    }
  }


  async checkUserVerified(content, wizard) {
    this.wizard = wizard
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
    await this.authService.fetch_pharmacy_user(this.userData, this.selectedCustomer, this.selectedPharmacy.pharmacyId).then(resp => {
      if (resp.response === 'No user found with the given criteria') {
        this.pharmacyUser = resp;
        this.userStatus = 'unverified';
        this.spinner.hide();
        this.openModal(content, 'md');
      } else {
        this.pharmacyUser = resp;
        this.frontLicense = resp.hasOwnProperty('licenseFront_temp_encrypted') && resp.licenseFront_temp_encrypted !== 'IA==' ? atob(resp.licenseFront_temp_encrypted) : '';
        this.backLicense = resp.hasOwnProperty('licenseBack_temp_encrypted') && resp.licenseBack_temp_encrypted !== 'IA==' ? atob(resp.licenseBack_temp_encrypted) : '';
        this.medicareCard = resp.hasOwnProperty('medicareCardNumber') && resp.medicareCardNumber !== 'IA==' ? atob(resp.medicareCardNumber) : '';
        this.pensionCard = resp.hasOwnProperty('pensionCardNumber') && resp.pensionCardNumber !== 'IA==' ? atob(resp.pensionCardNumber) : '';
        this.userStatus = this.pharmacyUser.Status === 'verified' ? 'Verified' : 'unverified'
        this.spinner.hide();
        if (this.userStatus === 'unverified') {
          this.openModal(content, 'md');
          this.showDoc = true;
        } else {
          this.showDoc = false;
        }
      }
    });
  }


  checkProdEmpty() {
    this.orderedItems.pop();
    if (this.ScriptRows.length === 0 && this.unMatchedScripts.length === 0 && this.orderedItems.length === 0) {
      this.fetchedPharmacies.map(data => {
        data.collapse = true;
        return data;
      });
      this.index = -1;
    }
  }


  OnChangeType(type: any) {
    this.trackFileUpload = false;
    this.file = null;
  }

  nextStep(wizard: WizardComponent) {
    this.wizard = wizard;
    this.wizard.goToNextStep();
  }

  createPharmacyUser() {
    this.showMessageBlock = false
    this.showUpload = true;
    if (this.pharmacyUser.response === 'No user found with the given criteria') {
      const body = {
        userId: this.userData.auth.data._id,
        pharmacyId: this.selectedPharmacy.pharmacyId
      }
      this.authService.createPharmacy_user(this.userData, body).then((resp) => {
        if (resp.status === 400) {
          if (resp.error.pharmacyUser.Status === 'unverified') {
            this.pharmacyUser = resp.error.pharmacyUser;
          }
        } else {
          this.pharmacyUser = resp.pharmacyUser_created;
        }
      }).catch(err => {
        this.toastr.error('Failed to create Pharmacy User', 'Error!', {
          positionClass: 'toast-top-center'
        });
      })
    }
  }

  closeModal(content6) {
    if (this.fileCount !== 2) {
      this.toastr.error('Upload both front side and back side of the license', 'Error!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.modalService.dismissAll(content6);
  }


  OnChangeAddress(event: any, content) {
    this.type = null;
    this.uploadVia = '';
    this.showUpload = false;
    this.selectedAddress = event;
    if (this.selectedAddress !== undefined) {
      if (this.selectedAddress.verifiedPharmacies.length === 0) {
        if (this.selectedAddress.icon === 'ft-x-circle text-danger') {
          this.userStatus = 'unverified';
          this.showMessageBlock = true;
          this.showUpload = false;
          this.openModal(content, 'md');
        }
        const body = [{
          propName: 'user_address', value: this.selectedAddress._id
        }, {
          propName: 'Status', value: 'unverified'
        }];
        this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, body).then((resp) => {
        });
      } else {
        if (!this.selectedAddress.verifiedPharmacies.includes(this.selectedPharmacy.pharmacyId)) {
          this.userStatus = 'unverified';
          this.showMessageBlock = true;
          this.openModal(content, 'md');
          const body = [{
            propName: 'Status', value: 'unverified'
          }, {
            propName: 'user_address', value: this.selectedAddress._id
          }];
          this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, body).then((resp) => {
          });
        }
      }
    }
  }


  submitAddress(content) {
    this.submitted = true;
    if (this.addressForm.invalid) {
      return;
    }
    const body = {
      userId: this.selectedCustomer._id,
      addressType: this.addressForm.value.addressType,
      address: this.addressForm.value.address,
      suburb: this.addressForm.value.suburb,
      state: this.addressForm.value.state,
      postcode: Number(this.addressForm.value.postcode)
    }

    this.authService.create_Address(body).then((resps: any) => {
      this.toastr.info('Address created successfully', 'Updated', {
        positionClass: 'toast-top-center'
      });
      this.userAddress.push(resps);
      this.authService.fetch_user_address(this.userData).then((resp) => {
        this.userAddress = resp;
        this.userAddress.map((address) => {
          address.completeAddress = address.address + ' ' + address.suburb + ' ' + address.state + ' ' + address.postcode;
          address.icon = 'ft-x-circle text-danger';
        });
        this.userAddress.filter((address) => {
          if (address.verifiedPharmacies.length > 0) {
            address.icon = address.verifiedPharmacies.includes(this.selectedPharmacy.pharmacyId) ? 'ft-check text-success' : 'ft-x-circle text-danger';
          } else {
            address.icon = 'ft-x-circle text-danger';
          }
        });
      });
      this.modalService.dismissAll(content);
      this.addressForm.reset();
      this.submitted = false;
    }).catch(err => {
      this.toastr.error('Failed to create the address', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }


  confirm(deliverIns: string, content) {
    if (this.pickup) {
      this.modalService.dismissAll(content);
    } else {
      this.delivery_Instruction = deliverIns;
      this.modalService.dismissAll(content);
      this.amountForDelivery();
    }
  }

  uploadNext() {
    if (this.type === '') {
      this.toastr.error('Please upload document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (!this.file && this.type !== 'DriversLicense') {
      this.toastr.error('Please upload' + this.type + ' document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if ((this.frontFile === undefined || this.backFile === undefined) && this.type === 'DriversLicense') {
      this.toastr.error('Please upload ' + this.type + ' document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    if (this.type === 'DriversLicense') {
      this.trackFileUpload = true;
      this.spinner.show(undefined,
        {
          zIndex: 99999,
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true,
        });
      this.authService.upload_licence(this.userData, this.pharmacyUser, this.frontFile.target.files[0], 'licenseFront_temp_encrypted').then((resp: any) => {
        if (resp.status === 400) {
          this.trackFileUpload = false;
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        }
        if (resp.status === 500) {
          this.trackFileUpload = false;
          this.toastr.error('Upload only png and jpeg files', 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
        } else {
          this.frontLicense = atob(resp.licenseFront_temp_encrypted);
          this.fetchPharmacyUser();
          this.pensionCard = '';
          this.medicareCard = '';
          this.spinner.hide();
          this.trackFileUpload = false;
        }
      }).catch(err => {
        this.trackFileUpload = false;
        this.toastr.error('Failed to upload the license', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
      });
      this.authService.upload_licence(this.userData, this.pharmacyUser, this.backFile.target.files[0], 'licenseBack_temp_encrypted').then(async (resp: any) => {
        if (resp.status === 400) {
          this.trackFileUpload = false;
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 500) {
          this.trackFileUpload = false;
          this.toastr.error('Upload only png and jpeg files', 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
        } else {
          this.frontLicense = atob(resp.licenseFront_temp_encrypted);
          this.toastr.success('License Uploaded Successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          this.fetchPharmacyUser();
          this.pensionCard = '';
          this.medicareCard = '';
          this.spinner.hide();
          this.trackFileUpload = false;
          this.modalService.dismissAll();
        }
      }).catch(err => {
        this.toastr.error('Failed to upload the license', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
      });
    } else {
      this.trackFileUpload = true;
      this.spinner.show(undefined,
        {
          zIndex: 99999,
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      this.fileCount += 1;
      this.authService.upload_licence(this.userData, this.pharmacyUser, this.file.target.files[0], this.type).then(async (resp: any) => {
        if (resp.status === 400) {
          this.trackFileUpload = false;
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 500) {
          this.trackFileUpload = false;
          this.toastr.error('Upload only png and jpeg files', 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
        } else {
          if (this.type === 'pensionCardNumber') {
            this.pensionCard = atob(resp.pensionCardNumber);
            this.frontLicense = '';
            this.backLicense = '';
          } else {
            this.frontLicense = '';
            this.backLicense = '';
            this.medicareCard = atob(resp.medicareCardNumber);
          }
          this.toastr.success('License Uploaded Successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          this.fetchPharmacyUser();
          this.showMessageBlock = true;
          this.showUpload = false;
          this.spinner.hide();
          this.trackFileUpload = false;
          this.modalService.dismissAll();
        }
      }).catch(err => {
        this.toastr.error('Failed to upload the license', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.trackFileUpload = false;
        this.spinner.hide();
      });
    }
  }


  goNext(modal) {
    if (!this.type) {
      this.toastr.clear();
      this.toastr.error('Please upload document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (!this.file && this.type !== 'DriversLicense') {
      this.toastr.clear();
      this.toastr.error(this.type === 'pensionCardNumber' ? 'Please upload Pension card document' : 'Please upload Medicare card document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if ((this.frontFile === undefined || this.backFile === undefined) && this.type === 'DriversLicense') {
      this.toastr.clear();
      this.toastr.error('Please upload DriversLicense document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    if (this.type === 'DriversLicense') {
      this.trackFileUpload = true;
      this.spinner.show(undefined,
        {
          zIndex: 99999,
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true,
        });
      this.authService.upload_licence(this.userData, this.pharmacyUser, this.frontFile.target.files[0], 'licenseFront_temp_encrypted').then((resp: any) => {
        if (resp.status === 400) {
          this.trackFileUpload = false;
          this.toastr.clear();
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        }
        if (resp.status === 500) {
          this.trackFileUpload = false;
          this.toastr.clear();
          this.toastr.error('Upload only png and jpeg files', 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
        } else {
          this.frontLicense = atob(resp.licenseFront_temp_encrypted);
          this.fetchPharmacyUser();
          this.pensionCard = '';
          this.medicareCard = '';
          this.spinner.hide();
          this.trackFileUpload = false;
        }
      }).catch(err => {
        this.trackFileUpload = false;
        this.toastr.clear();
        this.toastr.error('Failed to upload the license', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
      });
      this.authService.upload_licence(this.userData, this.pharmacyUser, this.backFile.target.files[0], 'licenseBack_temp_encrypted').then(async (resp: any) => {
        if (resp.status === 400) {
          this.trackFileUpload = false;
          this.toastr.clear();
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 500) {
          this.trackFileUpload = false;
          this.toastr.clear();
          this.toastr.error('Upload only png and jpeg files', 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
        } else {
          this.frontLicense = atob(resp.licenseFront_temp_encrypted);
          this.toastr.clear();
          this.toastr.success('License Uploaded Successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          this.fetchPharmacyUser();
          this.pensionCard = '';
          this.medicareCard = '';
          this.spinner.hide();
          this.trackFileUpload = false;
          modal.dismiss('Cross-Click');
          this.nextStep(this.wizard);
        }
      }).catch(err => {
        this.toastr.clear();
        this.toastr.error('Failed to upload the license', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
      });
    } else {
      this.trackFileUpload = true;
      this.spinner.show(undefined,
        {
          zIndex: 99999,
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      this.fileCount += 1;
      this.authService.upload_licence(this.userData, this.pharmacyUser, this.file.target.files[0], this.type).then(async (resp: any) => {
        if (resp.status === 400) {
          this.trackFileUpload = false;
          this.toastr.clear();
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 500) {
          this.trackFileUpload = false;
          this.toastr.clear();
          this.toastr.error('Upload only png and jpeg files', 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
        } else {
          if (this.type === 'pensionCardNumber') {
            this.pensionCard = atob(resp.pensionCardNumber);
            this.frontLicense = '';
            this.backLicense = '';
          } else {
            this.frontLicense = '';
            this.backLicense = '';
            this.medicareCard = atob(resp.medicareCardNumber);
          }
          this.toastr.clear();
          this.toastr.success('License Uploaded Successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          this.fetchPharmacyUser();
          this.showMessageBlock = true;
          this.showUpload = false;
          this.spinner.hide();
          modal.dismiss('Cross-Click');
          this.nextStep(this.wizard);
          this.trackFileUpload = false;
        }
      }).catch(err => {
        this.toastr.clear();
        this.toastr.error('Failed to upload the license', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.trackFileUpload = false;
        this.spinner.hide();
      });
    }
    this.userStatus = 'unverified';
    this.showDoc = true;
  }


  fetchPharmacyUser() {
    this.authService.fetch_pharmacy_user(this.userData, this.selectedCustomer, this.selectedPharmacy.pharmacyId).then(resp => {
      if (resp.response === 'No user found with the given criteria') {
        this.pharmacyUser = resp;
        this.userStatus = 'unverified'
      } else {
        this.pharmacyUser = resp;
        this.frontLicense = resp.hasOwnProperty('licenseFront_temp_encrypted') && resp.licenseFront_temp_encrypted !== 'IA==' ? atob(resp.licenseFront_temp_encrypted) : '';
        this.backLicense = resp.hasOwnProperty('licenseBack_temp_encrypted') && resp.licenseBack_temp_encrypted !== 'IA==' ? atob(resp.licenseBack_temp_encrypted) : '';
        this.medicareCard = resp.hasOwnProperty('medicareCardNumber') && resp.medicareCardNumber !== 'IA==' ? atob(resp.medicareCardNumber) : '';
        this.pensionCard = resp.hasOwnProperty('pensionCardNumber') && resp.pensionCardNumber !== 'IA==' ? atob(resp.pensionCardNumber) : '';
        this.userStatus = this.pharmacyUser.Status.toLowerCase();
      }
    });
  }

  closeMe() {
    if (this.fileCount !== 2) {
      this.toastr.error('Please upload your license', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.modalService.dismissAll();
    this.fileCount = 0;
  }

  selectMe(me: string) {
    this.choiceMade = me;
    if (me === 'Carrier') {
      this.showAuthTPAForm = !this.showAuthTPAForm;
    } else {
      this.showAuthTPAForm = false;
    }
  }

  fillData(fullName: string, relType: string, modal: any) {
    if (fullName === '' || relType === '') {
      this.toastr.error('Please fill the Details', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.myorderObj = {...this.myorderObj, ['relationship_with_customer']: relType};
    this.myorderObj = {...this.myorderObj, ['pickup_name']: fullName};
    this.forPickUpCalculation();
    modal.dismiss('Cross-Click');
  }

  closeMod(modal) {
    this.showUpload = false;
    this.type = null;
    this.trackFileUpload = false;
    modal.dismiss('Cross-Click');
  }

  gotoNext(wizard, docModal) {
    this.uploadVia = '';
    this.type = null;
    this.selectedAddress = undefined;
    this.showMessageBlock = true;
    this.selectedUserAddress = null;
    if (!this.selectedPharmacy) {
      this.toastr.clear();
      this.toastr.warning('Please select the pharmacy', 'Warning!', {
        positionClass: 'toast-top-center'
      });
      return;
    }


    if (this.ScriptRows.length > 0) {
      const sortedPrescriptions = this.ScriptRows.sort((a, b) => {
        if (a.script_status === 'Match' && b.script_status !== 'Match') {
          return -1; // a comes before b
        }
        if (a.script_status !== 'Match' && b.script_status === 'Match') {
          return 1; // b comes before a
        }
        return 0; // leave unchanged
      });
      this.ScriptRows = sortedPrescriptions;
    }
    if (this.selectedPharmacy.medicationNotAvailable.length > 0) {
      this.selectedPharmacy.medicationNotAvailable.forEach(data => {
        const unMatchScriptObj = {
          'token': data.token,
          'product_brand_name': data.product_brand_name,
          'scriptName': data.medication_display,
          'product_name': data.product_name,
          'medication_strength': data.medication_strength,
          'quantity': 1,
          'price': data.price,
          'repeats': data.repeats,
          'instruction': data.instruction,
          'note': data.note,
          'duration': '',
          'globalProductId': '',
          'GlobalProductOriginalName': '',
          'matchStatus': 'Unmatched',
          'scriptData': {
            'uploadedScript': '',
            'type': ''
          }
        }
        this.orderedItems.push(unMatchScriptObj);
      });
    }
    if (this.unMatchedScripts.length > 0) {
      this.unMatchedScripts.map((script) => {
        script.quantity = 1;
        return script;
      });
    }

    if (this.orderedItems.length === 0) {
      this.toastr.clear();
      this.toastr.warning('Kindly choose the medication', 'Warning!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.pickup = true;
    if (this.pickup) {
      this.forPickUpCalculation();
    }
    this.nextStep(wizard);
    this.showUpload = false;
    this.type = null;
    this.checkUserVerified(docModal, wizard);
  }


  amountForDelivery() {
    this.totalCost = 0;
    this.orderedItems.forEach(data => {
      this.totalCost += (Number(data.price) * Number(data.quantity));
    });
    this.totalAmount_to_paid = this.totalCost;
    this.GST_amount = Number((this.totalAmount_to_paid * Number(this.selectedPharmacy?.pharmacyDetails?.GSTPercentage) / 100).toFixed(2));
    this.totalAmount_to_paid += Number(this.selectedPharmacy?.pharmacyDetails?.delivery_charge);
    const freighttChargeTax = Number(((Number(this.selectedPharmacy?.pharmacyDetails?.delivery_charge) * Number(this.selectedPharmacy?.pharmacyDetails?.GSTPercentage)) / 100).toFixed(2));
    this.GST_amount += freighttChargeTax;
    this.GST_amount = this.totalAmount_to_paid / Number(this.selectedPharmacy?.pharmacyDetails?.GSTPercentage);
  }


  forPickUpCalculation() {
    this.totalCost = 0;
    this.orderedItems.forEach(data => {
      this.totalCost += (Number(data.price) * Number(data.quantity));
    });
    this.totalAmount_to_paid = this.totalCost;
    this.GST_amount = Number((this.totalAmount_to_paid * Number(this.selectedPharmacy?.pharmacyDetails?.GSTPercentage) / 100).toFixed(2));
    this.GST_amount = this.totalAmount_to_paid / Number(this.selectedPharmacy?.pharmacyDetails?.GSTPercentage);
  }


  removeFrightCharge(modal) {
    this.forPickUpCalculation();
    modal.dismiss('Cross-Click');
  }

  validateText(token) {
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(token.value)) {
      this.validation = true;
    } else {
      this.validation = false;
    }
  }

  OnChangeUploadType(event) {
    this.uploadType = event.target.value;
  }

  paste(url, modal) {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (url === '') {
      this.toastr.error('Please Enter ScriptImage URL', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(url) ? true : false;
    if (!pattern.test(url)) {
      this.toastr.error('URL is invalid', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    // const a = document.createElement('a');
    // a.href = url;
    // a.target = '_blank';
    // a.click();

    if (this.scriptImage_for === 'for_each_medicine') {
      const script = {
        'uploadedScript': url,
        'type': this.uploadType
      }
      this.orderedItems[this.selectedMedicine_Index].scriptData = script;
    } else {
      this.scriptImage = url;
    }
    this.toastr.clear();
    this.toastr.success('URL inserted', 'Success', {
      positionClass: 'toast-top-center'
    });
    modal.dismiss('Cross-Click');
  }

  OnChangeState(value: string) {
    this.searchByPharmacty = false;
    this.selectedState = value;
    this.customerNote = null;
    this.submitted = false;
    this.disableBTn = false;
    unMatchedProd = [];
    this.index = -1;
    this.fetchedPharmacies = [];
    this.orderedItems = [];
    this.trackingItems = [];
    this.ScriptRows = [];
    this.pharmacySelected = null;
    this.selectedPharmacy = null;
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
    this.toastr.clear();
    this.fetchedPharmacies = [];
    this.authService.fetch_pharmacies_product_match(this.userData, this.userData.auth.data._id, 'false',
      {'scriptIds': this.myscripts}, value).then((pharmacies: any) => {
      if (pharmacies.status === 400) {
        this.toastr.clear();
        this.toastr.error('No pharmacies found', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.fetchedPharmacies = [];
        this.spinner.hide();
        return;
      }
      if (pharmacies.status === 404) {
        this.toastr.clear();
        this.toastr.error('No pharmacies found', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.fetchedPharmacies = [];
        this.spinner.hide();
        return;
      }
      if (pharmacies.status === 500) {
        this.toastr.clear();
        this.toastr.error('No pharmacies found', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.fetchedPharmacies = [];
        this.spinner.hide();
        return;
      }
      if (pharmacies.data.length === 0) {
        this.toastr.clear();
        this.toastr.error('No pharmacies found', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.fetchedPharmacies = [];
        this.spinner.hide();
        return;
      }
      this.fetchedPharmacies = pharmacies.data.map(ele => ({
        ...ele, collapse: true
      }));
      this.spinner.hide();
      this.fetchedPharmacies.map((pharma: any) => {
        pharma.medicationAvailable.map(medi => {
          medi.matchedScript = {
            ...medi.matchedScript,
            'ProdStock_status': '',
            'prodPrice': '',
            'resetDropdown': null,
            'resetDropdownId': null
          }
        });
        return pharma;
      });
    });
  }


  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }


  confirm_Modal_open(confirmModal1, item: any, script, type = 'product') {
    this.removableProd.productName = type === 'product' ? item.product_name : item.token;
    this.removableProd.type = type;
    this.removableProd.token = script.token;
    this.selectedScript = script;
    this.modalService.open(confirmModal1, {size: 'md', backdrop: 'static'});
  }

  removeItem(modal) {
    const filteredData = this.ScriptRows.filter((data) => {
      return data.product_name !== this.selectedScript.product_name;
    });
    this.ScriptRows = filteredData;

    this.ScriptRows.map(script => {
      if (script.token === this.removableProd.token) {
        script.itemsOrdered = script.itemsOrdered.filter(prod => prod.product_name !== this.removableProd.productName);
      }
    });
    if (this.orderedItems.length > 0) {
      const filteredData = this.orderedItems.filter((data) => {
        if (data.product_name.toLowerCase().includes(this.removableProd.productName.toLowerCase())) {
          return false;
        } else if (data.product_name.toLowerCase() === this.removableProd.productName.toLowerCase()) {
          return false;
        }
        return true;
      });
      this.orderedItems = filteredData;
    }
    if (this.trackingItems.length > 0) {
      const filteredData1 = this.trackingItems.filter((data) => {
        if (data.product_name.toLowerCase().includes(this.removableProd.productName.toLowerCase())) {
          return false;
        } else if (data.product_name.toLowerCase() === this.removableProd.productName.toLowerCase()) {
          return false;
        }
        return true;
      });
      this.trackingItems = filteredData1;
    }
    this.fetchedPharmacies.map((pharma: any) => {
      pharma.medicationAvailable.map((medi) => {
        if (medi.matchedScript.product_name === this.selectedScript.product_name) {
          medi.matchedScript = {
            ...medi.matchedScript,
            'ProdStock_status': '',
            'prodPrice': '',
            'resetDropdown': null,
            'resetDropdownId': null
          }
        }
        return medi;
      });
      return pharma;
    });
    const temp = this.ScriptRows.filter((data) => {
      if (data.product_name === '') {
        return data.product_brand_name !== this.selectedScript.product_brand_name;
      } else {
        return data.product_name !== this.selectedScript.product_name;
      }
    });
    this.ScriptRows = temp;
    const unmatcheScriptsFilter = this.unMatchedScripts.filter((data) => {
      if (data.product_name === '') {
        return data.product_brand_name !== this.selectedScript.product_brand_name;
      } else {
        return data.product_name !== this.selectedScript.product_name;
      }
    });
    this.unMatchedScripts = unmatcheScriptsFilter;
    if (this.orderedItems.length > 0) {
      if (this.pickup) {
        this.forPickUpCalculation();
      } else {
        this.amountForDelivery();
      }
    } else {
      this.totalAmount_to_paid = 0;
      this.GST_amount = 0;
      this.totalCost = 0;
    }
    this.clearTable(this.selectedScript);
    modal.dismiss('Cross-Click');
  }


  filterToRemoveItem(script, product) {
    if (this.ScriptRows.length > 0) {
      const temp1 = this.ScriptRows.filter((data) => data.product_name !== script.product_name);
      this.ScriptRows = temp1;
    }
    if (this.orderedItems.length > 0) {
      const temp2 = this.orderedItems.filter((data) => {
        if (data.product_name.toLowerCase().includes(product.toLowerCase())) {
          return false;
        } else if (data.product_name.toLowerCase() === product.toLowerCase()) {
          return false;
        }
        return true;
      });
      this.orderedItems = temp2;
    }
    if (this.trackingItems.length > 0) {
      const temp3 = this.trackingItems.filter((data) => {
        if (data.product_name.toLowerCase().includes(product.toLowerCase())) {
          return false;
        } else if (data.product_name.toLowerCase() === product.toLowerCase()) {
          return false;
        }
        return true;
      });
      this.trackingItems = temp3;
    }
  }

  OnProductChange(event, script, matchedProduct, medicationNotAvailable = [], index, pharmacy) {
    if (event === undefined) {
      return;
    }
    this.disableBTn = false;
    if (event.isDiscontinued) {
      this.fetchedPharmacies.map((pharma: any) => {
        pharma.medicationAvailable.map((medi) => {
          if (medi.matchedScript.product_name === script.product_name) {
            medi.matchedScript = {
              ...medi.matchedScript,
              'ProdStock_status': '',
              'prodPrice': '',
              'resetDropdown': undefined,
              'resetDropdownId': undefined
            }
          }
          return medi;
        });
        return pharma;
      });
      this.toastr.clear();
      this.toastr.info('This product is currently discontinued in ' + pharmacy.pharmacy_name, 'Message', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (event.availableStock <= 0 && !pharmacy.pharmacyDetails.acceptBackOrders) {
      this.toastr.clear();
      this.toastr.info('This product is currently out of stock, and ' + pharmacy.pharmacy_name + ' is not accepting backorders at the moment.', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.fetchedPharmacies.map((pharma: any) => {
        pharma.medicationAvailable.map((medi) => {
          if (medi.matchedScript.product_name === script.product_name) {
            medi.matchedScript = {
              ...medi.matchedScript,
              'ProdStock_status': '',
              'prodPrice': '',
              'resetDropdown': undefined,
              'resetDropdownId': undefined
            }
          }
          return medi;
        });
        return pharma;
      });
      return;
    }
    this.selected_Product = event;
    this.selected_index = index;
    this.selectedScript = script;
    this.fetchedPharmacies.map((pharma: any) => {
      pharma.medicationAvailable.map(medi => {
        if (medi?.matchedScript?.product_name === script.product_name) {
          medi.matchedScript.ProdStock_status = this.selected_Product.availableStock;
          medi.matchedScript.prodPrice = this.selected_Product.productCost;
          medi.matchedScript.resetDropdown = this.selected_Product.pharmacyProductName;
          medi.matchedScript.resetDropdownId = this.selected_Product.pharmacyProductId;
        }
        return medi;
      });
      return pharma;
    });

    if (this.selected_Product.availableStock <= 0 && this.selectedPharmacy.pharmacyDetails.acceptBackOrders) {
      this.toastr.clear();
      this.toastr.info('This product is currently on back order - there may be additional delays while we order stock in for you', 'Info', {
        positionClass: 'toast-top-center',
      });
    }
    const orderItemObject = {
      'token': script.token,
      'product_brand_name': script.product_brand_name,
      'scriptName': script.medication_display,
      'product_name': this.selected_Product.pharmacyProductName,
      'medication_strength': script.medication_strength,
      'quantity': 1,
      'price': this.selected_Product.productCost,
      'stockStatus': this.selected_Product.availableStock > 0 && !this.selectedPharmacy.pharmacyDetails.acceptBackOrders ? 'In-stock' :
        this.selected_Product.availableStock <= 0 && this.selectedPharmacy.pharmacyDetails.acceptBackOrders ? 'Back order' :
          this.selected_Product.availableStock > 0 && this.selectedPharmacy.pharmacyDetails.acceptBackOrders ? 'In-stock' : 'Out of stock',
      'repeats': script.repeats,
      'instruction': script.instruction,
      'note': script.note,
      'duration': '',
      'globalProductId': this.selected_Product.GlobalProductId,
      'GlobalProductOriginalName': this.selected_Product.GlobalProductOriginalName,
      'matchStatus': 'Partial match',
      'scriptData': {
        'uploadedScript': '',
        'type': ''
      }
    }

    script = {
      ...script, 'script_status': 'Match',
      'itemsOrdered': [orderItemObject],
      'erxNames': script.product_name === '' ? script.product_brand_name : script.product_name.includes(script.medication_strength) ? script.product_name : script.product_name + ' ' + script.medication_strength,
      'trackingItems': [{...orderItemObject, 'scriptData': script}]
    };
    if (this.ScriptRows.length > 0) {
      const checkScriptAlreayExist = this.ScriptRows.find(scr => scr.medication_display === script.medication_display);
      if (checkScriptAlreayExist) {
        const filteredDupli = this.ScriptRows.filter((scr) => {
          return scr.medication_display !== script.medication_display;
        });
        this.ScriptRows = filteredDupli;
        this.ScriptRows = [...this.ScriptRows, script];
      } else {
        this.ScriptRows.map((scr) => {
          if (scr.erxNames === script.erxNames) {
            // product replacement if contains multiple flavors
            scr.itemsOrdered = [orderItemObject];
          } else {
            unMatchedProd = [];
            unMatchedProd.push(script);
          }
          return scr;
        });
        this.ScriptRows = [...this.ScriptRows, ...unMatchedProd];
      }
    } else {
      this.ScriptRows = [...this.ScriptRows, script];
    }

    this.trackingItems = [];
    this.orderedItems = [];
    this.ScriptRows.forEach(data => {
      if (data.script_status !== 'Unmatched') {
        this.orderedItems = [...this.orderedItems, ...data.itemsOrdered];
      }
    });
    this.ScriptRows.forEach(data => {
      if (data.script_status !== 'Unmatched') {
        this.trackingItems = [...this.trackingItems, ...data.trackingItems];
      }
    });
  }


  removeProductSelected(product: string, script) {
    this.filterToRemoveItem(script, product ? product : script.product_name);
    this.fetchedPharmacies.map((pharma: any) => {
      pharma.medicationAvailable.map(medi => {
        if (medi?.matchedScript?.product_name === script.product_name) {
          medi.matchedScript.ProdStock_status = '';
          medi.matchedScript.prodPrice = '';
          medi.matchedScript.resetDropdown = undefined;
          medi.matchedScript.resetDropdownId = undefined;
          medi.matchedScript.showRow = false;
        }
        return medi;
      });
      return pharma;
    });
  }


  clearTable(script) {
    this.fetchedPharmacies.map((pharma: any) => {
      if (pharma.pharmacyId === this.selectedPharmacy.pharmacyId) {
        pharma.medicationAvailable.map(medi => {
          if (medi?.matchedScript?.product_name === script.product_name) {
            medi.matchedScript.ProdStock_status = '';
            medi.matchedScript.prodPrice = '';
            medi.matchedScript.resetDropdown = undefined;
            medi.matchedScript.resetDropdownId = undefined;
            medi.matchedScript.showRow = false;
          }
          return medi;
        });

        if (pharma.medicationNotAvailable.length > 0) {
          pharma.medicationNotAvailable.map(medi => {
            if (medi.product_name === script.product_name) {
              medi.showRow = false;
            }
            return medi;
          });
        }
      }

      return pharma;
    });
  }

  removeUnmatchedScript(script, pharmacy) {
    this.fetchedPharmacies.map((pharma: any) => {
      if (pharma.pharmacyId === pharmacy.pharmacyId) {
        pharma.medicationNotAvailable.map(medi => {
          if (medi.product_name === script.product_name) {
            medi.showRow = false;
          }
          return medi;
        });
      }
      return pharma;
    });

    const temp = this.orderedItems.filter((data) => data.product_name !== script.product_name);
    this.orderedItems = temp;

    const temp1 = this.unMatchedScripts.filter(data => data.product_name !== script.product_name);
    this.unMatchedScripts = temp1;

    const temp2 = this.ScriptRows.filter((data) => data.product_name !== script.product_name);
    this.ScriptRows = temp2;

  }
}
