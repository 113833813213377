<section id="ngx-datatable-filter">
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <h4 class="card-title mt-1">My Scripts</h4>
      <button class="btn secondary-btn" (click)="openScriptModal(scriptToken)">
        <i class="ft-plus mr-1"></i>Add Script
      </button>
    </div>
    <div class="card-body">
      <button class="btn badge primary-btn float-right text-white" (click)="refreshTokens()">
        Refresh {{ responeCount }} <i class="ft-refresh-ccw"></i></button>
      <button [disabled]="selectedScripts.length === 0" class="btn badge primary-btn float-right text-white mr-1"
              (click)="Order_now()">
        Order now
      </button>
      <!--Mobile View -->
      <div class="card1 d-sm-none d-lg-none d-md-none d-xl-none" *ngFor="let script of erxResponse;let i = index;">
        <div class="card1-image">
          <div placement="top" ngbTooltip="The paper prescription is required when this script is dispensed"
               style="width: 100px; height: 100px;"
               *ngIf="script?.medicationObject[0]?.scriptType !== 'Electronic prescription'">
            <img src="assets/img/scriptImg.jpg" alt="" width="100%" height="auto">
          </div>

          <div class="qr-code-block"
               *ngIf="script?.medicationObject[0]?.scriptType === 'Electronic prescription'">
            <qrcode *ngIf="script?.medicationObject[0].status === 'active'"
                    [qrdata]="script?.medicationObject[0]?.token"
                    [width]="60"
                    [errorCorrectionLevel]="'M'">
            </qrcode>
          </div>
        </div>
        <div class="check-box-content">
          <div class="checkbox">
            <input type="checkbox" (change)="onCheckSelectScript(script,$event)" #checkBox
                   id="{{script?.medicationObject[0]?.token}}">
            <label
              for="{{script?.medicationObject[0]?.token}}"><span>{{ script?.medicationObject[0]?.token }}</span></label>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-12 text-right">
                  <span class="btn badge text-capitalize" [ngClass]="{'bg-light-success': script?.medicationObject[0].status === 'active',
                            'bg-light-warning': script?.medicationObject[0].status === 'dispensed',
                            'bg-secondary-exhausted': script?.medicationObject[0].status === 'exhausted',
                            'bg-light-danger': script?.medicationObject[0].status === 'cancelled',
                            'bg-light-secondary': script?.medicationObject[0].status === 'ceased',
                            'bg-expired': script?.medicationObject[0].status === 'expired',
                            'bg-light-dark': script?.medicationObject[0].status === 'disabled'
                            }">{{ script?.medicationObject[0].status }}</span>
            </div>
          </div>
          <div class="row row-border" *ngFor="let medicine of script?.medicationObject;let j = index;">
            <div class="col-sm-12 col-12 pt-1">
              <p>{{ medicine?.medication_display }}</p>
            </div>
            <div class="col-sm-12 col-12 pt-1">
              <p *ngIf="medicine.chartBasedPrescription">Supplies
                Remaining: {{ medicine.repeats === 0 ? '&#x221E;' : medicine.repeats }}</p>
              <p *ngIf="!medicine.chartBasedPrescription">Supplies Remaining:{{ medicine.repeats }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-12 d-flex justify-content-center align-items-center pt-2">
              <a class="badge primary-btn text-white"
                 [class.disabled]="script?.medicationObject[0].status !== 'active'"
                 (click)="script?.medicationObject[0].status !== 'active' ? $event.preventDefault() : sendScript(script)"
              >Order Now
              </a>
              <a class="badge primary-btn text-white ml-1"
                 (click)="openConfirmModal(delete_confirmModal,script,checkBox)">Delete
                Script</a>
            </div>
            <div class="col-sm-12 col-12 d-flex justify-content-center align-items-center pt-2">
              <a
                *ngIf="script?.medicationObject[0].status === 'active' && script?.medicationObject[0]?.scriptType === 'Electronic prescription'"
                [href]="[this.ERX_VIEW_URL + script?.medicationObject[0]?.token]"
                target="_blank" routerLinkActive="active"
                class="badge primary-btn text-white">View Script</a>

              <a class="badge primary-btn text-white ml-1"
                 *ngIf="script?.medicationObject[0].status === 'active'  && script?.medicationObject[0]?.scriptType === 'Electronic prescription'"
                 (click)="openConfirmModal(sendScriptModal,script)">Share Script</a>
            </div>
          </div>
        </div>
      </div>

      <!--Desktop View -->
      <div class="table-responsive d-none d-sm-none d-lg-block d-md-block d-xl-block" *ngIf="erxResponse.length > 0">
        <table class="table mt-2">
          <thead class="text-center thead-light">
          <tr>
            <th>Token</th>
            <th class="text-center" colspan="5">
              <span>Prescription</span>
            </th>
            <th class="text-center">Status</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody class="text-center">
          <tr *ngFor="let script of erxResponse;let i = index;">
            <td>
              <div style="display: flex; flex-direction: column; align-items: center;">
                <div placement="top" ngbTooltip="The paper prescription is required when this script is dispensed"
                     style="width: 40px; height: 40px;"
                     *ngIf="script?.medicationObject[0]?.scriptType !== 'Electronic prescription'">
                  <img src="assets/img/scriptImg.jpg" alt="" width="100%" height="auto">
                </div>

                <div class="qr-code-block"
                     *ngIf="script?.medicationObject[0]?.scriptType === 'Electronic prescription'">
                  <qrcode *ngIf="script?.medicationObject[0].status === 'active'"
                          [qrdata]="script?.medicationObject[0]?.token"
                          [width]="60"
                          [errorCorrectionLevel]="'M'">
                  </qrcode>
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="d-inline-block mr-2 mb-2">
                    <div class="checkbox">
                      <input type="checkbox" (change)="onCheckSelectScript(script,$event)" #checkBox
                             [disabled]="script?.medicationObject[0]?.status !== 'active'"
                             id="{{'Web' +script?.medicationObject[0]?.token}}">
                      <label
                        for="{{'Web' +script?.medicationObject[0]?.token}}"><span>{{ script?.medicationObject[0]?.token }}</span></label>
                    </div>
                  </li>
                </ul>

              </div>
            </td>
            <td colspan="5">
              <div class="row" *ngFor="let medicine of script?.medicationObject;let j = index;">
                <div class="col-md-6 col-12 text-left">
                  <p>{{ medicine?.medication_display }}</p>
                </div>
                <div class="col-md-6 col-12">
                  <p *ngIf="medicine.chartBasedPrescription">Supplies
                    Remaining: {{ medicine.repeats === 0 ? '&#x221E;' : medicine.repeats }}</p>
                  <p *ngIf="!medicine.chartBasedPrescription">Supplies Remaining:{{ medicine.repeats }}</p>
                </div>
              </div>
            </td>
            <td>
                          <span class="btn badge cursor-none text-capitalize" [ngClass]="{'bg-light-success': script?.medicationObject[0].status === 'active',
                            'bg-light-warning': script?.medicationObject[0].status === 'dispensed',
                            'bg-secondary-exhausted': script?.medicationObject[0].status === 'exhausted',
                            'bg-light-danger': script?.medicationObject[0].status === 'cancelled',
                            'bg-light-secondary': script?.medicationObject[0].status === 'ceased',
                            'bg-expired': script?.medicationObject[0].status === 'expired',
                            'bg-light-dark': script?.medicationObject[0].status === 'disabled'
                            }">{{ script?.medicationObject[0].status }}</span>
            </td>

            <td>
              <div ngbDropdown>
                <button class="btn badge text-white primary-btn mr-1" id="dropdownBasic1" ngbDropdownToggle>Actions
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <a class="dropdown-item"
                     [class.disabled]="script?.medicationObject[0].status !== 'active'"
                     (click)="script?.medicationObject[0].status !== 'active' ? $event.preventDefault() : sendScript(script)"
                  >Order Now
                  </a>
                  <a
                    [href]="[this.ERX_VIEW_URL + script?.medicationObject[0]?.token]"
                    target="_blank" routerLinkActive="active"
                    *ngIf="script?.medicationObject[0].status === 'active' && script?.medicationObject[0]?.scriptType === 'Electronic prescription'"
                    class="dropdown-item">View Script</a>

                  <a class="dropdown-item"
                     *ngIf="script?.medicationObject[0].status === 'active'  && script?.medicationObject[0]?.scriptType === 'Electronic prescription'"
                     (click)="openConfirmModal(sendScriptModal,script)">Share Script</a>
                  <a class="dropdown-item" (click)="openConfirmModal(delete_confirmModal,script,checkBox)">Delete
                    Script</a>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <ngb-pagination class="float-right" [collectionSize]="this.max * 10"
                        (pageChange)="pagination($event,'personal')"
                        [(page)]="pagNum" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>


<ng-template #delete_confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete My Script</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to delete this script?</p>
    <div class="row mt-1">
      <div class="col-12 col-md-12 col-lg-12 text-center">
        <button class="btn btn-danger" (click)="deleteMyScript(modal)">Yes</button>
        <button class="btn btn-dark ml-1" (click)="modal.dismiss('Cross click')">No</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #ViewOrder let-modal>
  <div class="modal-header">
    <h4 class="modal-title">View Order - #{{ selectedOrder.orderNo }}</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xl-12 col-12">
        <div style="overflow-y: scroll !important;overflow-x: hidden !important;">
          <h5 class="font-weight-bold font-italic">User Information</h5>
          <hr>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-8 col-12">
              <div class="row">
                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-4 col-12">
                  <label class="font-weight-bold">Name</label>
                  <p>{{ selectedOrder?.name }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Mobile Number</label>
                  <p>{{ selectedOrder?.mobile }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Email</label>
                  <p style="word-break: break-word">{{ selectedOrder?.email }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Order By</label><br>
                  <p>{{ selectedOrder?.orderedBy }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Ordered Date and Time</label><br>
                  <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12"
                     *ngIf="selectedOrder?.status === 'cancelled' && (selectedOrder?.rejectReason !== '' || selectedOrder?.cancellation_reason.reason !== '')">
                  <label class="font-weight-bold">Cancellation Reason</label><br>
                  <p *ngIf="selectedOrder?.rejectReason !== ''">{{ selectedOrder?.rejectReason }}</p>
                  <p
                    *ngIf="selectedOrder?.cancellation_reason.reason !== ''">{{ selectedOrder?.cancellation_reason.reason }}</p>
                </div>

                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Delivery Type</label><br>
                  <span class="badge bg-light-primary">{{ selectedOrder?.pickup ? 'Pickup' : 'Delivery' }}</span>
                  <span class="ml-2">{{ !selectedOrder?.pickup ? selectedOrder?.deliveryInstruction : '' }}</span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Status</label><br>
                  <span
                    class="badge bg-light-success btn text-capitalize" [ngClass]="{'bg-light-success btn' : pharmacyUser?.pharmacyUserStatus === 'verified','bg-light-dark btn' : pharmacyUser?.pharmacyUserStatus === 'blacklisted',
                            'bg-light-danger btn': pharmacyUser?.pharmacyUserStatus === 'unverified'}">
                              {{ pharmacyUser?.pharmacyUserStatus }}</span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                  <label class="font-weight-bold">Order Status</label><br>
                  <span
                    *ngIf="selectedOrder.partialShipmentStatus !== 'shipped' && selectedOrder.partialShipmentStatus !== 'completed'"
                    class="text-capitalize">{{ selectedOrder.status }}</span>
                  <span
                    *ngIf="selectedOrder.partialShipmentStatus === 'shipped' || selectedOrder.partialShipmentStatus === 'completed'"
                    class="text-capitalize">{{ selectedOrder.partialShipmentStatus }}
            </span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                  <label class="font-weight-bold">Address</label>
                  <p>
                    <b>{{ selectedOrder?.addressType }}</b> {{ selectedOrder?.address + ' ' + selectedOrder?.suburb + ' ' + selectedOrder?.state + ' ' + selectedOrder?.postcode }}
                  </p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                     *ngIf="selectedOrder.pickup && selectedOrder?.pickup_name !== ''">
                  <label class="font-weight-bold">Pickup Name</label><br>
                  <p>{{ selectedOrder?.pickup_name }} - {{ selectedOrder?.relationship_with_customer }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                     *ngIf="this.selectedOrder.uploadedScript!== '' && this.selectedOrder.uploadedScript!== ' '">
                  <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                    <i
                      *ngIf="showUrl"
                      class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                      (click)="downloadScript(this.selectedOrder?.uploadedScript)"></i>
                  </label>
                  <br>
                  <div *ngIf="showUrl">
                    <img class="cursor-pointer" src="{{this.selectedOrder?.uploadedScript}}" alt="" width="60"
                         height="40"
                         (click)="isCollapsed = !isCollapsed"
                         [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                    <div id="collapseExample" [ngbCollapse]="isCollapsed">
                      <img src="{{this.selectedOrder?.uploadedScript}}" alt="" width="400" height="300">
                    </div>
                  </div>
                  <p *ngIf="!showUrl">{{ this.selectedOrder?.uploadedScript }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-4 col-12">
              <div class="chat-container" *ngIf="customerNotes.length > 0;">
                <div class="chat-header bg-secondary">Customer Follow Up Note</div>
                <div class="chat-box">
                  <div class="messages">
                    <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                      {{ note.fallowUp_note }}
                      <p
                        class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                        {{ note.note_addedBy_userName }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1">
            <h5 class="font-weight-bold font-italic">User Script</h5>
            <hr>
          </div>
          <div class="table-responsive">
            <table class="table items-table">
              <thead>
              <tr>
                <!--              <th class="text-left">QR Code</th>-->
                <th>Token Number</th>
                <th>Script Info</th>
                <th>Brand Name</th>
                <th>Product Name</th>
                <!--              <th>Medication Strength</th>-->
                <th>Quantity</th>
                <th>Partial Shipped Quantity</th>
                <th>Remaining Quantity</th>
                <th>Price</th>
                <!--              <th>Repeats</th>-->
                <th>Instruction</th>
                <th>Note</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.selectedOrder?.orderedItem;let i = index;">
                <!--              <td class="py-0">-->
                <!--                <qrcode [qrdata]="item?.token" [width]="60" [errorCorrectionLevel]="'M'"></qrcode>-->
                <!--              </td>-->
                <td>
                  <div class="d-flex">
                    <input type="text" class="text-uppercase" name="token" #newToken id="item{{i}}"
                           [value]="item.token"
                    >
                    <span class="ft-check-square text-success cursor-pointer ml-2 mt-1"
                          [disabled]="valid"
                          (click)="updateToken(newToken.value,this.selectedOrder,i,modal)">
                  </span>
                  </div>
                </td>
                <td class="text-center">
                  <div class="d-flex">
                    <img *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                         class="cursor-pointer"
                         src="{{ item?.scriptData?.uploadedScript }}"
                         alt="" width="60" height="40"
                         (click)="viewScript_image(item?.scriptData?.uploadedScript,view_script_image)">
                    <span ngbTooltip="Download ScriptImage"
                          *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                          class="ft-download-cloud ml-2 cursor-pointer"
                          (click)="downloadScript(item?.scriptData?.uploadedScript)"></span>
                  </div>
                  <a *ngIf="item?.scriptData?.type === 'url'" target="_blank"
                     href="{{item?.scriptData?.uploadedScript}}" class="text-primary">
                <span style="text-decoration: underline" ngbTooltip="{{item?.scriptData?.uploadedScript}}"
                      placement="top">Click Here</span>
                  </a>
                </td>
                <td>{{ item?.product_brand_name }}</td>
                <td class="cursor-pointer"
                    (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}
                </td>
                <!--              <td>{{item?.medication_strength}}</td>-->
                <td>{{ item?.quantity }}</td>
                <td>{{ item?.shippedQuantity }}</td>
                <td>{{ item?.remainingQuantity }}</td>
                <td>${{ item?.price }}</td>
                <!--              <td>{{item?.repeats}}</td>-->
                <td>{{ item?.instruction }}</td>
                <td>{{ item?.note }}</td>
              </tr>

              <!--              <tr>-->
              <!--                <td colspan="5" class="text-right"><b>Item Total</b></td>-->
              <!--                <td>${{ selectedOrder?.itemsCost  | number:'1.2-2' }}</td>-->
              <!--              </tr>-->
              <!--              <tr *ngIf="!selectedOrder?.pickup">-->
              <!--                <td colspan="5" class="text-right"><b>Delivery Charge:</b></td>-->
              <!--                <td>-->
              <!--                  ${{ selectedOrder?.pharmacyId.freightCharge }}-->
              <!--                </td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td colspan="5" class="text-right"><b>Total Amount:</b></td>-->
              <!--                <td><p class="font-weight-bold font-medium-1">${{ selectedOrder?.totalAmount | number:'1.2-2' }}<br>-->
              <!--                  <span-->
              <!--                    class="font-weight-normal font-small-3">*Includes GST of ${{ selectedOrder.taxAmount | number:'1.2-2' }}</span>-->
              <!--                </p></td>-->
              <!--              </tr>-->
              </tbody>
            </table>
          </div>
          <span class="text-info mt-1 d-sm-block d-lg-none"><i class="ft-info mr-1"></i>Please scroll across for more detail</span>
          <br>
          <h5 class="font-weight-bold font-italic">Pharmacy Details</h5>
          <hr>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="col-12">
                <label class="font-weight-bold">Pharmacy Name</label>
                <p>{{ selectedOrder?.pharmacyId?.pharmacy_name }}</p>
              </div>
              <div class="col-12 mt-2">
                <label class="font-weight-bold">Mobile Number</label>
                <p>{{ selectedOrder?.pharmacyId?.phoneNumber }}</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="col-12">
                <label class="font-weight-bold">Email</label>
                <p>{{ selectedOrder?.pharmacyId?.email }}</p>
              </div>
              <div class="col-12">
                <label class="font-weight-bold">Pharmacy Address</label>
                <p>{{
                    selectedOrder?.pharmacyId?.address + ' ' + selectedOrder?.pharmacyId?.suburb + ' ' + selectedOrder?.pharmacyId?.state +
                    ' ' + selectedOrder?.pharmacyId?.postcode
                  }}</p>
              </div>
            </div>
          </div>
          <!--Additional Cost -->
          <br>
          <h5 class="font-weight-bold font-italic" *ngIf="selectedOrder?.additionalCosts.length > 0">Additional
            Costs</h5>
          <div class="row" *ngIf="selectedOrder?.additionalCosts.length > 0">
            <div class="col-md-12 col-12">
              <div class="table-responsive">
                <table class="table m-0">
                  <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Payment Status</th>
                    <th>Amount Added By</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let extracost of selectedOrder?.additionalCosts;let i = index;">
                    <td>{{ extracost.amount }}</td>
                    <td>{{ extracost.description }}</td>
                    <td>{{ extracost.paid ? 'Paid' : 'Un-Paid' }}</td>
                    <td>{{ extracost.amount_addedBy_userName }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--      Payment Details-->
          <br>
          <div
            *ngIf="selectedOrder.status === 'paymentProcess' || selectedOrder.status === 'pendingShipment'  || selectedOrder.status === 'dispense' || selectedOrder.status === 'shipped' || selectedOrder.status === 'completed'">
            <h5 class="font-weight-bold font-italic">Payment Details
            </h5>
            <hr>
            <div class="table-responsive">
              <table class="table table-bordered m-0">
                <thead class="table-header">
                <tr>
                  <th>Order No</th>
                  <th>Order Date</th>
                  <th>Payment Method</th>
                  <th>Transaction Number</th>
                  <th>Date & Time</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let payment of selectedOrder.orderPaymentDetails">
                  <td>{{ payment?.paymentAdditionalDetails[0]?.orderNumber }}</td>
                  <td>
                    <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                  </td>
                  <td>{{ payment?.orderPayment_method }}</td>
                  <td>{{ payment?.orderPayment_reference }}</td>
                  <td>{{ payment?.orderPayment_initiated_date | date:'mediumDate' }} {{ payment?.orderPayment_initiated_date | date:'shortTime' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!--      Delivery Details-->
          <br>
          <div *ngIf="selectedOrder?.shippingDetails.length > 0">
            <h5 class="font-weight-bold font-italic">Delivery Details</h5>
            <hr>
            <div class="table-responsive">
              <table class="table table-bordered m-0">
                <thead class="table-header">
                <tr>
                  <th>Order No</th>
                  <th>Order Date</th>
                  <th>Carer Number</th>
                  <th>Connote Number</th>
                  <th>Delivery Date & Time</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let ele of selectedOrder?.shippingDetails">
                  <td>{{ ele?.itemShippedDetails[0]?.orderNumber }}</td>
                  <td>
                    <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                  </td>
                  <td>{{ ele?.freightCarrier }}</td>
                  <td>{{ ele?.trackingNo }}</td>
                  <td>{{ ele?.deliveredDate  | date:'mediumDate' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Cancel Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">Would like to know the order Cancellation</p>
    <div class="row">
      <div class="col-12">
        <label>Reason</label>
        <input type="text" class="form-control square" #reasonbox>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger" (click)="cancelOrder(reasonbox)">Yes</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #Modal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Repeat Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <div class="row" *ngFor="let order of selectedOrder.orderedItem;let i = index;">
        <div class="col-md-12 col-12">
          <label>Repeat Token</label>
          <div class="d-flex">
            <input type="text" class="form-control square text-uppercase size" name="token" #newToken id="item{{i}}"
                   [value]="order.token"
                   (keyup)="validateToken(newToken.value)">
            <button class="btn badge primary-btn ml-2"
                    [disabled]="valid"
                    (click)="fillDetails(newToken.value,this.selectedOrder,i,modal,confirmReOrderModal)">Re-Order
            </button>
          </div>
          <p *ngIf="valid" class="text-danger">Token is required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <label class="font-weight-bold">Customer Notes</label>
          <div class="d-flex">
                <textarea rows="3" cols="3" #noteArea
                          class="form-control square"></textarea>
            <button class="btn-submit" [disabled]="disablebtn" (click)="sendNote(noteArea,modal)"><i
              class="ft-check-square text-success"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #confirmReOrderModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Message</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">{{ this.message }}</p>
    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger" (click)="reOrder(modal)">OK</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #view_script_image let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Script Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img [src]="viewScript" alt="" width="100%" height="auto">
    </div>
  </div>
</ng-template>

<!--ERX Add Token-->
<ng-template #scriptToken let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Enter Token Number</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="scriptForm" (ngSubmit)="add(modal,smallModal)">
      <div class="row">
        <div class="col-md-12 col-12">
          <label class="form-control-label">Token Number</label>
          <input class="form-control input-md text-uppercase"
                 formControlName="token" type="text">
          <p *ngIf="scriptForm.controls['token'].hasError('pattern')" class="text-danger">Invalid Token number</p>
          <p *ngIf="submitted && scriptForm.controls['token'].hasError('required')" class="text-danger">Token is
            required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12 px-2 py-2 text-center">
          <button type="submit" [disabled]="disablebtn" class="btn primary-btn text-white">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #sendScriptModal let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Share Script</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row d-sm-block d-lg-none d-md-none d-xl-none">
      <div class="col-md-12 col-12 col-lg-12 col-sm-12">
        <ngx-intl-tel-input
          #phoneInput
          cssClass="form-control"
          [cssClass]="'form-control'"
          [preferredCountries]="['us', 'in','au']"
          [enableAutoCountrySelect]="true"
          [enablePlaceholder]="true"
          [searchCountryFlag]="true"
          [searchCountryField]="['name', 'dialCode', 'iso2']"
          [selectFirstCountry]="false"
          [selectedCountryISO]="'au'"
          [maxLength]="10" style="width: 100% !important;"
          (countryChange)="onCountryChange($event,phoneInput)">
        </ngx-intl-tel-input>
      </div>
      <div class="col-md-12 col-12 col-lg-12 col-sm-12"
           *ngIf="submitted && this.phoneInput.phoneNumber === ''">
        <small class="text-danger">Phone Number is
          required</small>
      </div>

      <div class="col-md-12 col-12 col-lg-12 col-sm-12 pt-2 d-flex justify-content-center align-items-center">
        <button type="submit" class="btn primary-btn text-white" (click)="sendMyScript(modal,phoneInput)">
          Submit
        </button>
      </div>
    </div>


    <div class="d-none d-sm-none d-lg-block d-md-block d-xl-block">
      <div class="row ">
        <div class="col-md-6 col-12 col-lg-6 col-sm-12">
          <ngx-intl-tel-input
            #phoneInput
            cssClass="form-control"
            [cssClass]="'form-control'"
            [preferredCountries]="['us', 'in','au']"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="['name', 'dialCode', 'iso2']"
            [selectFirstCountry]="false"
            [selectedCountryISO]="'au'"
            [maxLength]="10" style="width: 100% !important;"
            (countryChange)="onCountryChange($event,phoneInput)">
          </ngx-intl-tel-input>
          <small class="text-danger" *ngIf="submitted && this.phoneInput.phoneNumber === ''">Phone Number is
            required</small>
        </div>
        <div class="col-md-6 col-12 col-lg-6 col-sm-12">
          <button type="submit" class="btn primary-btn text-white float-right" (click)="sendMyScript(modal,phoneInput)">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #smallModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Message</h4>
  </div>
  <div class="modal-content">
    <div class="modal-body text-center">
      <p>If you have a paper script or token, make sure to store it securely or dispose it properly when no longer
        needed.</p>
      <div class="text-center">
        <button class="btn primary-btn" (click)="d('Cross click')">OK</button>
      </div>
    </div>
  </div>
</ng-template>
