<section id="ngx-datatable-filter" xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-6 col-lg-6 col-12">
              <h4 class="card-title">Software vendors</h4>
            </div>
            <div class="col-md-6 col-lg-6 col-12">
              <button class="float-right btn secondary-btn" (click)="openModal(createContent)"><i
                class="ft-plus-circle mr-1"></i>Create Software Vendor
              </button>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="card-body">
            <h4 *ngIf="this.vendorsList.length === 0 && !isLoading" class="text-center">No Vendors found</h4>
            <div class="table-responsive" *ngIf="this.vendorsList.length > 0">
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th>#</th>
                  <th>Vendor Name</th>
                  <th>Category</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let vendor of vendorsList; let i = index;">
                  <td>{{ vendor.slNumber }}</td>
                  <td>{{ vendor.name }}</td>
                  <td>
                    {{
                      vendor.posIntegration && vendor.dispenseIntegration ? 'Pos Software, Dispense Software' : ''
                    }}
                    {{ vendor.posIntegration && !vendor.dispenseIntegration ? 'Pos Software' : '' }}
                    {{ vendor.dispenseIntegration && !vendor.posIntegration ? 'Dispense Software' : '' }}
                  </td>
                  <td>
                    <span class="cursor-pointer" (click)="openConfirmModal(createContent,vendor)"><i
                      class="ft-edit text-primary"></i></span>
                    <span class="cursor-pointer ml-1" (click)="openConfirmModal(confirmModal,vendor)"><i
                      class="ft-trash text-danger"></i></span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <ngb-pagination class="float-right" [collectionSize]="this.maxSize * 10"
                            (pageChange)="pagination($event)"
                            [(page)]="this.page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #confirmModal let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Vendor</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete this vendor?</p>
    <button class="cursor-pointer btn btn-danger" [disabled]="disbaleMe" (click)="deleteVendor(modal)">Yes</button>
  </div>
</ng-template>


<ng-template #createContent let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ isCreate ? 'Create Vendor' : 'Update Vendor' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="vendorForm" (ngSubmit)="registerVendor(modal,posIntegration,dispenseIntegration)">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-12">
          <label>Name</label>
          <input type="text" class="form-control" formControlName="name">
          <small class="text-danger" *ngIf="submitted && this.vendorForm.controls['name'].hasError('required')">Name is
            required</small>
          <small class="text-danger" *ngIf="this.vendorForm.controls['name'].hasError('pattern')">Invalid name</small>
        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-12">
          <div class=" d-flex flex-row p-2">
            <div class="checkbox">
              <input type="checkbox" [checked]="this.checkboxes.posIntegration" #posIntegration id="posIntegration">
              <label for="posIntegration">Pos Software</label>
            </div>
            <div class="checkbox ml-3">
              <input type="checkbox" [checked]="this.checkboxes.dispenseIntegration" #dispenseIntegration
                     id="dispenseIntegration">
              <label for="dispenseIntegration">Dispense Software</label>
            </div>
          </div>
          <small class="text-danger" *ngIf="submitted && (!posIntegration.checked && !dispenseIntegration.checked)">
            Please check any of the checkbox to enable software vendor</small>
        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-12 d-flex justify-content-center align-items-center flex-row p-2">
          <button type="submit" class="btn secondary-btn" [disabled]="disbaleMe">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
