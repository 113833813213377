<section id="shopping-cart">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12 col-lg-6 col-sm-12">
              <h2 class="card-title">Leads Customer</h2>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table m-0">
                <thead class="thead-light text-center">
                <tr>
                  <th>Token</th>
                  <th>First Initial</th>
                  <th>Last Initial</th>
                  <th colspan="7">Medication</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody class="text-center">
                <tr *ngFor="let script of customer_leads; let i = index;">
                  <td>
                    <div style="display: flex; flex-direction: column; align-items: center;">

                      <div placement="top" ngbTooltip="The paper prescription is required when this script is dispensed"
                           style="width: 40px; height: 40px;"
                           *ngIf="script?.medicationObject[0]?.scriptType !== 'Electronic prescription'">
                        <img src="assets/img/scriptImg.jpg" alt="" width="100%" height="auto">
                      </div>

                      <div class="qr-code-block"
                           *ngIf="script?.medicationObject[0]?.scriptType === 'Electronic prescription'">
                        <qrcode *ngIf="script?.medicationObject[0].status === 'active'"
                                [qrdata]="script?.medicationObject[0]?.token"
                                [width]="60"
                                [errorCorrectionLevel]="'M'">
                        </qrcode>
                      </div>

                      <span class="cursor-pointer"
                            (click)="copyToken(script?.medicationObject[0]?.token)">
                        <i class="ft-copy cursor-pointer mr-1"></i>
                        {{ script?.medicationObject[0]?.token }}</span>
                    </div>
                  </td>
                  <td>{{ script.firstInitial }}</td>
                  <td>{{ script.lastInitial }}</td>
                  <td colspan="7">
                    <div class="row" *ngFor="let medicine of script?.medicationObject;let j = index;">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                        <p
                          class="break-text">{{ medicine?.medication_display }}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <!--                      <span class="btn badge primary-btn text-white"-->
                      <!--                            (click)="syncTokens(syncModal,script)">Sync <i-->
                      <!--                        class="ft-refresh-ccw"></i></span>-->
                      <span class="btn badge badge-success text-white ml-1" (click)="openModal(viewModal,script)">Process
                        Script</span>
                      <span class="ft-trash cursor-pointer text-danger ml-1 mt-1"
                            (click)="openModal(delete_confirmModal,script,'md')"></span>
                    </div>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--Delete script-->
<ng-template #delete_confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete My Script</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to delete this script?</p>
    <div class="row mt-1">
      <div class="col-12 col-md-12 col-lg-12 text-center">
        <button class="btn btn-danger" (click)="deleteMyScript(modal)">Yes</button>
        <button class="btn btn-dark ml-1" (click)="modal.dismiss('Cross click')">No</button>
      </div>
    </div>
  </div>
</ng-template>

<!--Selection of customer for script assign-->

<ng-template #viewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Assign Script to User</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross-Click')"></span>
  </div>
  <div class="modal-dialog-scrollable">
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <h5 class="modal-title text-center">Script Details</h5>
          <div class="table-responsive">
            <table class="table m-0">
              <thead class="thead-light text-center">
              <tr>
                <th>Token</th>
                <th>First Initial</th>
                <th>Last Initial</th>
                <th>Medication</th>
              </tr>
              </thead>
              <tbody class="text-center">
              <tr>
                <td>
                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <div placement="top" ngbTooltip="The paper prescription is required when this script is dispensed"
                         style="width: 40px; height: 40px;"
                         *ngIf="this.selectedScript?.medicationObject[0]?.scriptType !== 'Electronic prescription'">
                      <img src="assets/img/scriptImg.jpg" alt="" width="100%" height="auto">
                    </div>

                    <div class="qr-code-block"
                         *ngIf="this.selectedScript?.medicationObject[0]?.scriptType === 'Electronic prescription'">
                      <qrcode *ngIf="this.selectedScript?.medicationObject[0].status === 'active'"
                              [qrdata]="this.selectedScript?.medicationObject[0]?.token"
                              [width]="60"
                              [errorCorrectionLevel]="'M'">
                      </qrcode>
                    </div>
                    <span class="cursor-pointer"
                          (click)="copyToken(this.selectedScript?.medicationObject[0]?.token)">
                        <i class="ft-copy cursor-pointer mr-1"></i>
                      {{ this.selectedScript?.medicationObject[0]?.token }}</span>
                  </div>
                </td>
                <td>{{ this.selectedScript.firstInitial }}</td>
                <td>{{ this.selectedScript.lastInitial }}</td>
                <td colspan="7">
                  <div class="row">
                    <div class="col-md-12 col-12 col-sm-12 col-12"><label style="font-weight: 600;"></label></div>
                  </div>
                  <div class="row" *ngFor="let medicine of this.selectedScript?.medicationObject;let j = index;">
                    <div class="col-md-12 col-12 col-sm-12 col-12">
                      <p>{{ medicine?.medication_display }}</p>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-10 col-lg-10 col-xl-10">
          <h5 class="modal-title text-center">Select Existing User</h5>
          <div class="table-responsive">
            <table class="table m-0">
              <thead class="thead-light text-center">
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Date of Birth</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody class="text-center">
              <tr *ngFor="let user of customers;">
                <td>{{ user.firstName }}</td>
                <td>{{ user.lastName }}</td>
                <td>{{ user.dateOfBirth ? user.dateOfBirth.split('T')[0].split('-').reverse().join('-') : '' }}</td>
                <td>{{ user.homeAdress }}</td>
                <td>
                  <button class="btn badge  text-white"
                          (click)="selectedUser = user"
                          [ngClass]="selectedUser?._id === user._id ? 'badge-danger' : 'badge-success'">Select
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 col-md-2 col-lg-2 col-xl-2">
          <div style="height: 25vh;" class="d-flex justify-content-center align-items-center">
            OR
            <button (click)="openRegisModal(AddCustomer)"
                    class="secondary-btn btn ml-3 badge"><i class="ft-plus-circle mr-1"></i>Add User
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center p-1">
        <button class="btn primary-btn text-white" [disabled]="!selectedUser"
                (click)="viewDetails(viewAssignDetails)">Continue <i class="ft-chevron-right ml-1"></i></button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #syncModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Assign Script to User</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close"
          (click)="modal.dismiss('Cross-Click'); this.selectedUser = null;"></span>
  </div>
  <div class="modal-body">
    <p class="text-center" *ngIf="this.message === 'The total user length is zero.'">No Match found Please Assign the
      user</p>
    <p *ngIf="selectedUser && message === ''" class="text-center">Syncing....</p>
  </div>
</ng-template>


<!--Customer Details for final submission-->
<ng-template #viewAssignDetails let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Assign Script to User</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close"
          (click)="modal.dismiss('Cross-Click'); this.selectedUser = null;"></span>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="selectedUser">

      <div class="col-md-12 col-12 col-lg-12 col-sm-12">
        <h4 class="modal-title">Script</h4>
        <div class="table-responsive">
          <table class="table">
            <thead class="thead-light text-center">
            <tr>
              <th>Token Number</th>
              <th>First Initial</th>
              <th>Last Initial</th>
              <th colspan="7">Medication</th>
            </tr>
            </thead>
            <tbody class="text-center">
            <tr>
              <td>
                <div style="display: flex; flex-direction: column; align-items: center;">
                  <div placement="top" ngbTooltip="The paper prescription is required when this script is dispensed"
                       style="width: 40px; height: 40px;"
                       *ngIf="this.selectedScript?.medicationObject[0]?.scriptType !== 'Electronic prescription'">
                    <img src="assets/img/scriptImg.jpg" alt="" width="100%" height="auto">
                  </div>

                  <div class="qr-code-block"
                       *ngIf="this.selectedScript?.medicationObject[0]?.scriptType === 'Electronic prescription'">
                    <qrcode *ngIf="this.selectedScript?.medicationObject[0].status === 'active'"
                            [qrdata]="this.selectedScript?.medicationObject[0]?.token"
                            [width]="60"
                            [errorCorrectionLevel]="'M'">
                    </qrcode>
                  </div>
                  <span class="cursor-pointer" (click)="copyToken(this.selectedScript?.medicationObject[0]?.token)"><i
                    class="ft-copy cursor-pointer mr-1"></i>{{ this.selectedScript?.medicationObject[0]?.token }}</span>
                </div>
              </td>
              <td>{{ this.selectedScript.firstInitial }}</td>
              <td>{{ this.selectedScript.lastInitial }}</td>
              <td colspan="7">
                <div class="row">
                  <div class="col-md-12 col-12 col-sm-12 col-12"><label style="font-weight: 600;"></label></div>
                </div>
                <div class="row" *ngFor="let medicine of this.selectedScript?.medicationObject;let j = index;">
                  <div class="col-md-12 col-12 col-sm-12 col-12">
                    <p>{{ medicine?.medication_display }}</p>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <h4 class="modal-title">User</h4>
    <hr style="margin-top: 2px !important; margin-bottom: 0.5rem !important;">
    <div class="row">
      <div class="col-md-3 col-12 col-lg-3 col-sm-12 p-2">
        <label class="font-weight-bold">First Name</label>
        <p>{{ selectedUser?.firstName }}</p>
      </div>
      <div class="col-md-3 col-12 col-lg-3 col-sm-12 p-2">
        <label class="font-weight-bold">Last Name</label>
        <p>{{ selectedUser?.lastName }}</p>
      </div>
      <div class="col-md-3 col-12 col-lg-3 col-sm-12 p-2">
        <label class="font-weight-bold">Date of Birth</label>
        <p>{{ selectedUser?.dateOfBirth ? selectedUser?.dateOfBirth.split('T')[0].split('-').reverse().join('-') : '' }}</p>
      </div>
      <div class="col-md-3 col-12 col-lg-3 col-sm-12 p-2">
        <label class="font-weight-bold">Email</label>
        <p>{{ selectedUser?.email }}</p>
      </div>
      <div class="col-md-12 col-12 col-lg-12 col-sm-12 text-center p-2">
        <p><b>Are you sure you want to assign the script to this user?</b></p>
        <button (click)="assignScript(modal)" class="btn primary-btn text-white mt-1 border-radius-btn">Yes
        </button>
      </div>
    </div>
  </div>
</ng-template>


<!--Customer Registration-->
<ng-template #AddCustomer let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Customer</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="userRegisteration" (ngSubmit)="onSubmit(modal,checkbox)">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-1">First Name</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-1"
                     formControlName="firstName"
              >
              <small class="text-danger" *ngIf="submitted && rf.firstName.hasError('required')">First Name is
                required</small>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-2">Middle Name</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-2"
                     formControlName="middleName">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-3">Last Name</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-3" formControlName="lastName">
              <small class="text-danger" *ngIf="submitted && rf.lastName.hasError('required')">Last Name is
                required</small>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-5">Phone Number</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-5" formControlName="number"
                     maxlength="10" minlength="8">
              <small class="text-danger" *ngIf="submitted && rf.number.hasError('required')">Phone number is
                required</small>
              <small class="text-danger" *ngIf="rf.number.hasError('pattern')">Invalid Phone number</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-4">Email</label>
            <div class="col-md-9">
              <input type="text" autocapitalize="off" class="form-control square" id="horizontal-form-4"
                     formControlName="email">
              <small class="text-danger" *ngIf="submitted && rf.email.hasError('required')">Email is required</small>
              <small class="text-danger" *ngIf="rf.email.hasError('email')">Invalid email</small>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-4">Date of Birth</label>
            <div class="col-md-9">
              <div class="input-group calendar-width">
                <input class="form-control square"
                       [minDate]="{year: 1901, month:1, day: 1}"
                       (dateSelect)="this.isFuture_Date = false; this.isValid_Date = true"
                       [maxDate]="maxDate" placeholder="yyyy-mm-dd" maxlength="10"
                       [(ngModel)]="model" [ngModelOptions]="{standalone: true}" name="dp" ngbDatepicker
                       #d2="ngbDatepicker">
                <div class="input-group-append cursor-pointer" (click)="d2.toggle()">
                  <div class="input-group-text">
                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                  </div>
                </div>
              </div>
              <small class="text-danger"> {{
                  submitted && !model ? 'Date of Birth is required' : !isValid_Date ? 'Invalid Date' : this.isFuture_Date ? 'Future date is not allowed' : ''
                }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-4">Password</label>
            <div class="col-md-9">
              <div class="input-group">
                <input type="{{ showpwd ? 'text' : 'password'}}" class="form-control square" formControlName="password">
                <div class="input-group-prepend cursor-pointer" (click)="showpwd = !showpwd">
                  <span class="input-group-text cursor-pointer"><i
                    [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
                </div>
              </div>
              <small class="text-danger" *ngIf="submitted && rf.password.hasError('required')">Password is
                required</small>
              <div *ngIf="userRegisteration.controls['password'].hasError('pattern')">
                <small class="text-danger">
                  Password must be at least seven characters long, contain at least one letter and one number.
                  <br>
                  Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                  Password must not use the same character repeatedly or have any sequential characters (for
                  example, AAAA or 1234)<br>
                  Password must not have sequential characters. <br>
                  Password must not be the same as one of your last four passwords. <br>
                </small>
              </div>
              <div *ngIf="isSequential">
                <small class="text-danger">Password must not use the same character repeatedly or have any
                  sequential characters (for example, AAAA or 1234)</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-4">Confirm Password</label>
            <div class="col-md-9">
              <div class="input-group">
                <input type="{{ showConfirmPassword ? 'text' : 'password'}}" class="form-control square"
                       formControlName="confirm_password">
                <div class="input-group-prepend cursor-pointer" (click)="showConfirmPassword = !showConfirmPassword">
                  <span class="input-group-text cursor-pointer"
                  ><i
                    [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
                </div>
              </div>
              <small class="text-danger" *ngIf="submitted && rf.confirm_password.hasError('required')">Confirm Password
                is
                required</small>
              <div *ngIf="userRegisteration.controls['confirm_password'].hasError('pattern')">
                <small class="text-danger">
                  Password must be at least seven characters long, contain at least one letter and one number.
                  <br>
                  Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                  Password must not use the same character repeatedly or have any sequential characters (for
                  example, AAAA or 1234)<br>
                  Password must not have sequential characters. <br>
                  Password must not be the same as one of your last four passwords. <br>
                </small>
              </div>
              <div *ngIf="ismisMatch">
                <small class="text-danger">Password is mismatch</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <label>Address Type</label>
          <select formControlName="addressType" id="" class="form-control square">
            <option value="Home">Home</option>
            <option value="Work">Work</option>
            <option value="Others">Others</option>
          </select>
          <small class="text-danger" *ngIf="submitted && rf.addressType.hasError('required')">Address Type is
            required</small>
        </div>
        <div class="col-md-6 col-12">
          <label>Address</label>
          <input type="text" class="form-control square" formControlName="address">
          <p class="text-danger" *ngIf="submitted && rf.address.hasError('required')">Address is required</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-12">
          <label>Suburb</label>
          <input type="text" class="form-control square" formControlName="suburb">
          <p class="text-danger" *ngIf="submitted && rf.suburb.hasError('required')">Suburb is required</p>
        </div>
        <div class="col-md-6 col-12">
          <label>State</label>
          <ng-select formControlName="state" placeholder="Select State" [(ngModel)]="selectedState">
            <ng-option *ngFor="let state of states" [value]="state">{{ state }}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="submitted && rf.state.hasError('required')">State is required</small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <label>Postcode</label>
          <input type="text" class="form-control square" formControlName="postcode" maxlength="4">
          <small class="text-danger" *ngIf="submitted && rf.postcode.hasError('required')">Postcode is required</small>
          <small class="text-danger" *ngIf="userRegisteration.controls['postcode'].hasError('pattern')">Invalid
            postcode</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <small class="checkbox text-center">
            <input type="checkbox" id="acceptTerms" #checkbox>
            <label for="acceptTerms"></label>
            By ticking this box you confirm that you have permission from the subject of care to create an account for
            them and they
            have given you permission to access prescription information on their behalf
          </small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <button type="submit" class="btn primary-btn mb-2"><i class="ft-check-square mr-1"></i>Save</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
