import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../../shared/storage/local-storage.service';
import {AuthService} from '../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from './User';
import {Clipboard} from '@angular/cdk/clipboard';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MonthFormatPipe} from '../../../shared/pipes/month-format.pipe';
import {NgxSpinnerService} from 'ngx-spinner';

const moment = require('moment');

export interface Pagination<T> {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  data: T[];
}

@Component({
  selector: 'app-customer-leads',
  templateUrl: './customer-leads.component.html',
  styleUrls: ['./customer-leads.component.scss']
})
export class CustomerLeadsComponent implements OnInit {

  userData: any;
  customer_leads = [];
  selectedScript: any;
  customers = [];
  page = 1;
  maxSize = 0;
  selectedUser: User;
  states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];
  selectedState = null;
  showpwd = false;
  showConfirmPassword = false;
  data: any[] = []; // Your complete dataset
  paginatedData: any[] = []; // Data for the current page
  currentPage = 1;
  itemsPerPage = 10;
  model = null;
  totalPages = 0;
  dob = '';
  isSequential = false;
  isValid_Date = true;
  isFuture_Date = false;
  ismisMatch = false;
  userRegisteration = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl(' '),
    lastName: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)]),
    confirm_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)]),
    addressType: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  });
  submitted = false;
  platform: any;
  pharmacyUser: any;
  pharmacy: any;
  userStatus: any;
  maxDate = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}
  message = '';

  // blocking not select future date for the dateofBirth

  constructor(private storage: LocalStorageService, private monthPipe: MonthFormatPipe,
              private modalService: NgbModal, private clipboard: Clipboard,
              private spinner: NgxSpinnerService,
              private authService: AuthService, private toastr: ToastrService) {
  }

  async ngOnInit(): Promise<any> {
    await this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    if (this.userData.auth.data.role !== 'Master Admin') {
      this.authService.fetch_particular_pharmacy(this.userData).then(async (resp) => {
        this.pharmacy = resp;
      });
    }
    await this.fetchScripts_leads();
  }

  async fetchScripts_leads() {
    await this.authService.getScripts_leads(this.userData, 'true').then((response: any) => {
      if (response.status === 404) {
        this.customer_leads = [];
        return;
      }
      this.customer_leads = response.data.map((data) => {
        data.firstInitial = data.firstName.charAt(0).toUpperCase();
        data.lastInitial = data.lastName.charAt(0).toUpperCase();
        return data;
      });
    }).catch(err => {
      this.customer_leads = [];
    })
  }

  async deleteMyScript(modal) {
    await this.authService.delete_my_script(this.userData, this.selectedScript._id).then(async (response: any) => {
      this.toastr.clear();
      this.toastr.error('Script Deleted Successfully', 'Message', {
        positionClass: 'toast-top-center'
      });
      await this.fetchScripts_leads();
      modal.dismiss('Cross-Click');
    })
  }

  get rf() {
    return this.userRegisteration.controls;
  }

  copyToken(token: string) {
    this.clipboard.copy(token);
    this.toastr.clear();
    this.toastr.info('Token copied', 'Info', {
      positionClass: 'toast-top-center'
    })
  }


  async fetchUser_scriptId(scriptId: string) {
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    await this.authService.fetchUserViaScriptId(this.userData, scriptId).then((response: any) => {
      if (response.status === 400) {
        this.spinner.hide();
        return;
      }
      if (response.status === 500) {
        this.spinner.hide();
        return;
      }
      if (response.status === 404) {
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error(response.error.hasOwnProperty('message') ? response.error.message : response.error.error, 'Message', {
          positionClass: 'toast-top-center'
        })
        return;
      }
      if (response.hasOwnProperty('message') && response.message === 'No user found') {
        this.spinner.hide();
        this.customers = [];
        this.maxSize = 0;
        return;
      }
      this.customers = response.results;
      this.customers.map(customer => customer.homeAdress = '');
      this.mapAddress();
      this.maxSize = response.count;
      this.spinner.hide();
    }).catch(err => this.spinner.hide());
  }

  async fetchUsers() {
    if (this.selectedScript.zen === '') {
      await this.NonZenValue();
      return;
    }
    await this.authService.getAllUsers(this.userData, 'false', 'true', String(this.page), this.selectedScript.zen).then((response: any) => {
      if (response.status === 500) {
        this.customers = [];
        this.maxSize = 0;
        return;
      }
      if (response.response === 'The total user length is zero.') {
        this.customers = [];
        this.maxSize = 0;
        this.NonZenValue();
        this.message = 'The total user length is zero.'
        return;
      }
      this.message = '';
      this.customers = response.results;
      this.customers.map(customer => customer.homeAdress = '');
      this.mapAddress();
      this.maxSize = response.totalPages;
    });
  }

  async NonZenValue() {
    await this.authService.getAllUsers(this.userData, 'false', 'true', String(this.page), '',
      this.selectedScript.firstInitial, this.selectedScript.lastName, this.selectedScript.dateOfBirth.split('T')[0]).then((response: any) => {
      if (response.status === 500) {
        this.customers = [];
        this.maxSize = 0;
        return;
      }
      if (response.response === 'The total user length is zero.') {
        this.customers = [];
        this.maxSize = 0;
        return;
      }
      this.customers = response.results;
      this.message = '';
      this.customers.map(customer => customer.homeAdress = '');
      this.mapAddress();
      this.maxSize = response.totalPages;
    });
  }


  async openModal(content, script, size = 'xl') {
    this.selectedScript = script;
    this.data = [];
    this.customers = [];
    await this.fetchUser_scriptId(script._id);
    // await this.fetchUsers();
    this.selectedUser = null;
    this.modalService.open(content, {size: size, backdrop: 'static'})
  }

  async syncTokens(content, script) {
    this.selectedScript = script;
    if (this.selectedScript.zen === '') {
      await this.authService.getAllUsers(this.userData, 'false', 'true', String(this.page), '',
        this.selectedScript.firstInitial, this.selectedScript.lastName, this.selectedScript.dateOfBirth.split('T')[0]).then((response: any) => {
        if (response.status === 500) {
          this.customers = [];
          this.maxSize = 0;
          return;
        }
        if (response.response === 'The total user length is zero.') {
          this.customers = [];
          this.maxSize = 0;
          this.message = 'The total user length is zero.';
          this.modalService.open(content, {size: 'md', backdrop: 'static'})
          return;
        }
        this.selectedUser = response.results[0];
        this.message = '';
        this.modalService.open(content, {size: 'md', backdrop: 'static'});
        this.assignScript(null);
      });
      return;
    }
    await this.authService.getAllUsers(this.userData, 'false', 'true', String(this.page), this.selectedScript.zen).then((response: any) => {
      if (response.status === 500) {
        this.customers = [];
        this.maxSize = 0;
        return;
      }
      if (response.response === 'The total user length is zero.') {
        this.customers = [];
        this.maxSize = 0;
        this.message = 'The total user length is zero.';
        this.modalService.open(content, {size: 'md', backdrop: 'static'})
        return;
      }
      this.message = '';
      this.selectedUser = response.results[0];
      this.modalService.open(content, {size: 'md', backdrop: 'static'});
      this.assignScript(null);
    });
  }


  mapAddress() {
    this.customers.map((customer) => {
      if (customer.addresses.length > 0) {
        let temp;
        const checkHome = customer.addresses.some((add) => add.addressType === 'Home');
        const checkWork = customer.addresses.some((add) => add.addressType === 'Work');
        const checkOther = customer.addresses.some((add) => add.addressType === 'Others');
        if (checkHome && checkWork && !checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Home');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (checkHome && !checkWork && checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Home');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (checkHome && checkWork && checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Home');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (!checkHome && checkWork && checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Work');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (checkHome && !checkWork && !checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Home');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (!checkHome && checkWork && !checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Work');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
        if (!checkHome && !checkWork && checkOther) {
          temp = customer.addresses.find((add) => add.addressType === 'Others');
          customer.homeAdress = !temp ? '' : temp.address + ' ' + temp.suburb + ' ' + temp.state + ' ' + temp.postcode;
        }
      } else {
        customer.homeAdress = ''
      }
    });
  }


  viewDetails(modal, size = 'xl') {
    this.modalService.open(modal, {size: size, backdrop: 'static'})
  }

  hasSequentialCharacters(password) {
    const length = password.length;
    // Loop through the password to check for sequential letters and numbers
    for (let i = 0; i < length - 1; i++) {
      const currentChar = password.charCodeAt(i);
      // Check for ascending sequences of numbers/letters
      if (password.charCodeAt(i + 1) === currentChar) {
        return true;
      }
      // Check for descending sequences of numbers/letters
      if (password.charCodeAt(i + 1) === currentChar) {
        return true;
      }

      // Check if the password is repeated
      if (password.charAt(i + 1) === password.charAt(i)) {
        return true;
      }
    }
    return false;
  }

  assignScript(modal) {
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.assign_script_for_user(this.userData, this.selectedUser._id, [this.selectedScript._id]).then(async (response: any) => {
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }

      if (response.hasOwnProperty('updatedScripts') && response.updatedScripts.length > 0) {
        let tokens = '';
        response.updatedScripts.forEach(script => {
          tokens += script.scriptToken + ', ';
        });
        tokens = tokens.substring(0, tokens.length - 2);
        this.toastr.clear();
        this.toastr.success(response.message + ' ' + tokens, 'Message', {
          positionClass: 'toast-top-center'
        });
      }
      this.spinner.hide();
      if (modal) {
        modal.dismiss('Cross-Click');
      }
      await this.fetchScripts_leads();
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.clear();
      this.spinner.hide();
      this.toastr.error('Internal server error', 'Error', {
        positionClass: 'toast-top-center'
      })
    });
  }


  async onSubmit(modal, checkBox) {
    this.submitted = true;
    if (!this.model) {
      return;
    }

    if (!this.isValidDate(this.model) && typeof this.model === 'string') {
      this.isValid_Date = false;
      return;
    }
    this.isValid_Date = true;
    const date = {
      ...this.model
    }
    this.isFuture_Date = this.isFutureDate(this.monthPipe.transform(date));
    if (this.isFuture_Date) {
      return;
    }
    if (this.userRegisteration.invalid) {
      return;
    }

    if (this.userRegisteration.value.password && this.hasSequentialCharacters(this.userRegisteration.value.password)) {
      this.isSequential = true;
      return;
    }

    if (this.hasSequentialCharacters(this.userRegisteration.value.password)) {
      this.isSequential = true;
      return;
    }
    this.ismisMatch = false;
    this.isSequential = false;

    if (this.userRegisteration.controls['password'].value !== this.userRegisteration.controls['confirm_password'].value) {
      this.toastr.error('Password mismatch', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }


    let TACAccepted = false;
    let PSAccepted = false;
    if (!checkBox.checked) {
      this.toastr.error('Please accept the terms and conditions', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    } else {
      TACAccepted = true;
      PSAccepted = true;
    }


    const body = {
      'firstName': this.userRegisteration.value.firstName,
      'middleName': this.userRegisteration.value.middleName,
      'lastName': this.userRegisteration.value.lastName,
      'email': this.userRegisteration.value.email,
      'password': this.userRegisteration.value.password,
      'number': '+61' + this.userRegisteration.value.number,
      'dateOfBirth': moment(this.monthPipe.transform(this.model)).format('YYYY-MM-DD'),
      'PSAccepted': PSAccepted,
      'TACAccepted': TACAccepted,
      'consentAccepted': false,
      'twofaEnabled': false,
      'role': 'User',
      'userType': 'user',
      'platformId': this.platform._id
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.register_new_user(body).then(async (resp: any) => {
      if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error('User already Exits', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      const addressBody = {
        userId: resp.userCreated._id,
        addressType: this.userRegisteration.value.addressType,
        address: this.userRegisteration.value.address,
        suburb: this.userRegisteration.value.suburb,
        state: this.userRegisteration.value.state,
        // tslint:disable-next-line:radix
        postcode: parseInt(this.userRegisteration.value.postcode)
      }
      await this.authService.create_Address(addressBody).then(async (addressResp: any) => {
        await this.authService.fetchCustomer(this.userData, resp.userCreated._id).then(async (customer: any) => {
          await this.fetchUser_scriptId(this.selectedScript._id);
        });
      });
      this.toastr.clear();
      this.toastr.success('User registered successfully', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
      this.submitted = false;
      this.userRegisteration.reset();
      this.spinner.hide();
    }).catch(err => {
      this.toastr.clear();
      this.spinner.hide();
      this.toastr.error('User registration failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
    modal.dismiss('Cross-Click')
  }

  openRegisModal(modal) {
    this.showpwd = false;
    this.model = null;
    this.selectedUser = null;
    this.showConfirmPassword = false;
    this.isValid_Date = true;
    this.isFuture_Date = false;
    this.submitted = false;
    this.userRegisteration.reset();
    this.isSequential = false;
    this.ismisMatch = false;
    this.modalService.open(modal, {size: 'xl', backdrop: 'static'});
  }


  isValidDate(date) {
    const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    return regex.test(date);
  }

  isFutureDate(inputDate) {
    const now = moment(); // Current date and time
    const date = moment(inputDate).format('YYYY-MM-DD');
    const dateToCheck = moment(date, 'YYYY-MM-DD', true);
    if (!dateToCheck.isValid()) {
      return !dateToCheck.isValid();
    }

    return dateToCheck.isAfter(now, 'day');
  }


}
